// Roboto
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/roboto-regular-webfont.eot');
    src: url('../fonts/roboto/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-regular-webfont.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/roboto-bold-webfont.eot');
    src: url('../fonts/roboto/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-bold-webfont.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/roboto-light-webfont.eot');
    src: url('../fonts/roboto/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto/roboto-light-webfont.woff') format('woff'),
         url('../fonts/roboto/roboto-light-webfont.ttf') format('truetype');
    font-weight: 300;
}
/*!
Template Name: FinanceCorp - HTML Template
Author: Softnio
Version: 1.1.1
Update: 11.18.2017
*/
/*!
 * Table of Contents
 * 1.0 - Fonts
 * 2.0 - Resets
 * 2.1 - General
 * 3.0 - Header/Navbar
 * 4.0 - Banner/Slider
 * 5.0 - Call-Action
 * 6.0 - Page Content
 * 7.0 - Forms
 * 8.0 - Stats
 * 9.0 - Testimonials
 * 10.0 - Clients Logo
 * 11.0 - Widgets
 * 12.0 - Footer / Copyright
 * 13.0 - Blog/News
 * 14.0 - FAQs
 * 15.0 - Feature Box
 * 16.0 - Teams Member
 * 17.0 - Gallery / Portfolio
 * 18.0 - Responsive
 * 19.0 - Version Wise
 * 20.0 - Extra/Additional
 */

@import "vendor.bundle.css";
@import "bootstrap.min.css";
@import "font-awesome.min.css";
@import "animate.min.css";

// /// VARIABLES /// //
// Theme Color Define
//$primary-color: 	#009750;
$primary-color: 	#007a75;
$secondary-color:	#EAA515;
$alternet-color:	#0A6339;
$default-color: 	#747d88;

$bg-light:			#f4f8f8;
$bg-dark:			#343640;
$bg-grey:			#e8e8ee;
$bg-trans:			transparent;

$bg-primary:		$primary-color;
$bg-secondary:		$secondary-color;
$bg-alternet:		$alternet-color;

$topbar-color:		#535457;
$topbar-bgcolor:	#fff;

$header-color: 		#535457;
$header-bgcolor: 	#fff;

$header-btn-color:			#fff;
$header-btn-bgcolor:		$secondary-color;
$header-btn-color-alt:		#fff;
$header-btn-bgcolor-alt:	$primary-color;

$footer-color: 		#fff;
$footer-bgcolor: 	#23262b;

$copyright-color: 	#fff;
$copyright-bgcolor: #131519;


$font-primary: "Poppins", sans-serif;
$font-secondary: "Roboto", sans-serif;

$font-size: 16px;
$font-weight: 300;
$font-height: 1.75;

$heading-color: #40454b;
$heading-color-alt: $primary-color;

$nav-color: $default-color;
$nav-color-over: $primary-color;
$nav-bgcolor: tranparent;
$nav-bgcolor-over: tranparent;
$nav-font-family: $font-primary;
$nav-font-weight: 400;
$nav-font-size: 14px;

$radius: 3px;
$radius-btn: 45px;

$space: 90px;
$space-sm: $space*0.50;
$space-md: $space*0.75;
$space-lg: $space*1.50;

$mgpd: 20px;
$mgpd-xs: $mgpd/2;
$mgpd-sm: $mgpd*0.75;
$mgpd-md: $mgpd*1.5;
$mgpd-lg: $mgpd*3;

// MIXIN
@mixin border-radius($rad:'') {
	@if $rad!='' {
    	-ms-border-radius: $rad;
		border-radius: $rad;
    } @else {
    	-ms-border-radius: $radius;
		border-radius: $radius;
    }
}
@mixin fonts($size, $height: '', $weight: '') {
	@if $size {
    	font-size: $size;
    } @else {
    	font-size: $font-size;
    }
    @if $height !='' {
    	line-height: $height;
    }
    @if $weight !='' {
    	font-weight: $weight;
    }
}

/* ===============================
	1.0 FONTS
   =============================== */
@import "roboto";
@import "poppins";
/* ===============================
    2.0 Resets
   =============================== */   
body {
	font-family: $font-primary;
	color: $default-color;
	@include fonts($font-size, $font-height, $font-weight);
}
html,body{
	overflow-x: hidden;
}
h1, h2, h3, h4, h5, h6 {
	font-family: $font-secondary;
	line-height: 1.15;
	font-weight: 400;
	color: $heading-color;
	&.color-secondary {
		color: $secondary-color;
	}
	&.color-primary {
		color: $primary-color;
	}
}
h1, .h1, .heading-lg, .heading-lg-lead {
	font-size: 2.5em;
}
h2, .h2 {
	font-size: 1.875em;
}
h3, .h3, h4, .h4, .heading-md,.heading-md-lead {
	font-size: 1.5em;
	font-weight: 300;
}
h4, .h4, h4.heading-md {
	font-size: 1.375em;
}
h5, .h5 {
	font-size: 1.125em;
}
h6, .h6, .heading-sm, .heading-sm-lead {
	font-size: .875em;
}
ul , ol{
	padding: 0px;
	margin: 0px;
	li{ 
		list-style: none;
	}
}
h1, h2, h3, h4, h5, h6, p, ul, ol, table{
	margin: 0 0 $mgpd*0.9;
	&:last-child {
		margin-bottom: 0;
	}
}
.heading-section{
	font-size: 1.875em;
	text-transform: uppercase;
	color: $heading-color;
	font-weight: 700;
	letter-spacing: 1px;
	&.heading-sm {
		font-size: 1.375em;
	}
	&.color-secondary {
		color: $secondary-color;
	}
	&.color-primary {
		color: $primary-color;
	}
	&.color-default {
		color: $default-color;
	}
}
.heading-lead {
	font-size: 2.25em;
	&.heading-sm {
		font-size: 1.5em;
	}
}
.heading-section, .heading-lead{
	margin-bottom: $mgpd-md;
	&:last-child {
		margin-bottom: 0
	}
	&:not(.with-line) {
		&+h3, &+h4, &+h5 {
		margin-top: -$mgpd;
	}
	}
}
.heading-section.with-line, .heading-lead.with-line {
	padding-bottom: $mgpd-sm;
	&::after {
		display: block;
	}
}
.heading-sm-lead, .heading-md-lead, .heading-lg-lead {
	letter-spacing: 4px;
	font-weight: 300;
	font-family: $font-primary;
	text-transform: uppercase;
	margin-bottom: 5px;
	&+h1,&+h2,&+h3,&+h4,&+h5,&+h4 {
		margin-top: 0;
	}
}
.heading-sm-lead, .heading-md-lead {
	color: $heading-color-alt;
	&.color-primary {
		color: $primary-color;
	}
	&.color-secondary {
		color: $secondary-color;
	}
}
.with-line {
	position: relative;
	padding-bottom: $mgpd-sm;
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		display: block;
		width: 60px;
		height: 2px;
		margin: $mgpd-sm 0 0;
		background-color: $primary-color;
		.light & {
			background-color: #fff;
		}
	}
	&.al-center::after, &.center::after {
		left: 50%;
		margin-left: -30px;
	}
	&.al-right::after {
		right: 0;
	}
}
.light, .light h1, .light h2, .light h3, .light h4, .light h5, .light h6 {
	color: #fff;
}
blockquote {
	margin: $mgpd-md 0;
	color: #888;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	p:last-child strong {
		color: $primary-color;
	}
}
p, ul, ol, table  {
	&+h1,&+h2,&+h3,&+h4,&+h5,&+h4 {
		margin-top: $mgpd*1.75;
	}
}
b, strong {
	font-weight: 600;
}
a{
	outline: 0;
	transition: all 0.5s;
	color: $primary-color;
	&:link, &:visited{
    	text-decoration: none;
	}
	&:hover, &:focus, &:active{
		outline: 0;
		color: $secondary-color;
	}
}
img {
	outline: 0;
	border: 0 none;
	max-width: 100%;
	height: auto;
	vertical-align: top;
	@include border-radius();
	& + h2, h3, h4{
		margin-top: $mgpd-sm;
	}
	&.alignright {
		padding: 0;
		float: right;
		margin:5px 0 10px $mgpd-md;
		h3 &, h4 &, h2 &, h1 & {
			margin-top: 0;
		}
	}
	&.alignleft {
		padding: 0;
		float: left;
		margin:5px $mgpd-md 10px 0;
		h3 &, h4 &, h2 &, h1 & {
			margin-top: 0;
		}
	}
	&.aligncenter {
		float: none;
		display: block;
		margin:5px auto $mgpd-sm;
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
hr, .hr {
	margin: $mgpd*2 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
input:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 transparent;
}
.al-center, .center {
	text-align: center;
	.with-line:after {
		left: 50%;
		margin-left: -35px;
	}
}
.al-right {
	text-align: right;
	.with-line:after {
		right: 0;
	}
}
.al-left {
	text-align: left;
}
.ucap {
	text-transform: uppercase;
}
#preloader {
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:#fff;
    z-index:10000;
}
#status {
    width:100px;
    height:100px;
    position:absolute;
    left:50%;
    top:50%;
    background-image:url(../image/loading.gif);
    background-repeat:no-repeat;
    background-position:center;
    background-size: 38px auto;
    margin:-50px 0 0 -50px;
}
#gmap {
	display: block;
	width: 100%;
	height: 400px;
	pointer-events: none;
	background: #58B;
}
.section-pad{
	padding-top: $space;
	padding-bottom: $space;
	&-sm {
		padding-top: $space-sm;
		padding-bottom: $space-sm;
	}
	&-md {
		padding-top: $space-md;
		padding-bottom: $space-md;
	}
	&-lg {
		padding-top: $space-lg;
		padding-bottom: $space-lg;
	}
	&.nopd {
		padding-top: 0;
		padding-bottom: 0;
	}
	&.nopd-b {
		padding-bottom: 0;
	}
	&.nopd-t {
		padding-top: 0;
	}
}
.pad-0, .nopd {
	padding: 0px;
}
.npl{
	padding-left: 0px;
}
.npr{
	padding-right: 0px;
}
.space {
	&-top {
		margin-top: $mgpd;
		&-sm {
			margin-top: $mgpd-sm;
		}
		&-md {
			margin-top: $mgpd-md;
		}
	}
	&-bottom {
		margin-bottom: $mgpd;
		&-sm {
			margin-bottom: $mgpd-sm;
		}
		&-md {
			margin-bottom: $mgpd-md;
		}
	}
}
.nomg {
	margin-top: 0;
	margin-bottom: 0;
}
.wide {
	&-lg {
		max-width:1170px;
		margin: 0 auto;
	}
	&-md {
		max-width: 970px;
		margin: 0 auto;
	}
	&-sm {
		max-width: 770px;
		margin: 0 auto;
	}
	&-xs {
		max-width: 570px;
		margin: 0 auto;
	}
}
.row-vm{
	align-items: center;
	display: flex;
	&.reverses {
		> div:last-child {
			order: 1;
		}
		> div:first-child {
			order: 2;
		}
	}
}
.container-fluid > .row > .row {
	margin-left: 0;
	margin-right: 0;
} 
.col-list .row + .row {
	margin-top: $mgpd-md;
}
.clear {
	clear: both;
	display: block;
	height: 0;
	width: 100%;
}
.first[class*="col-"] {
	clear: left;
}
.nomg[class*="wide-"] {
	margin: 0;
}
.img-shadow {
	box-shadow: 0 2px 26px 0 rgba(0,0,0,0.16);
}
.round {
	@include border-radius();
}
.no-round, .noround {
	border-radius: 0;
}
.imagebg {
	position: absolute;
	height: 100%;
	right: 0;
	top: 0;
	left: 0;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	z-index: 0;
	opacity: 0;
	transition: opacity .4s;
	.fixed-bg & {
		background-attachment: fixed
	}
	img	{
		display: none
	}
}
.image-on-left, .image-on-right {
	.imagebg {
		width: 50%;
	}
}
.image-on-left .imagebg {
	right: 50%;
}
.image-on-right .imagebg {
	left: 50%;
}
.has-bg-image {
	position: relative;
}
.bg-image-loaded {
  opacity: 1;
}
.has-bg, .fixed-bg {
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;
}
.has-bg {
	position: relative;
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		background-color: #000;
		opacity: .5;
		z-index: 1;
	}
	&.bg-primary:after {
		background-color: $primary-color;
	}
	&.bg-secondary:after {
		background-color: $secondary-color;
	}
	&.bg-alternet:after {
		background-color: $alternet-color;
	}
	&.bg-light:after {
		background-color: $bg-light;
	}
	&.dark-filter:after {
		opacity: .75;
	}
	&.darker-filter:after {
		opacity: .9;
	}
	&.light-filter:after {
		opacity: .25;
	}
	&.lighter-filter:after {
		opacity: .15;
	}
	&.no-filter:after {
		opacity: 0.01;
	}
}
.has-bg .container, .has-bg-image .container {
	position: relative;
	z-index: 2;
}
.fixed-bg {
	background-attachment: fixed;
}
.btn{
	font-family: $font-secondary;
	font-size: 12px;
	line-height: 1.4;
	font-weight: 700;
	padding: 12px 24px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
	border: 2px solid $primary-color;
	background-color: $primary-color;
	box-sizing: border-box;
	transition: all 0.4s;
	@include border-radius($radius-btn);
	&:hover, &:focus {
		background-color: $secondary-color;
		border-color: $secondary-color;
		color: #fff;
	}
	&.btn-alt {
		background: $secondary-color;
		border-color: $secondary-color;
		&:hover, &:focus {
			background: $primary-color;
			border-color: $primary-color;
		}
	}
	&.btn-outline{
		color: $primary-color;
		background-color: transparent;
		&:hover, &:focus {
			color: #fff;
			background: $primary-color;
			border-color: $primary-color;
		}
		&.btn-alt {
			color: $secondary-color;
			&:hover, &:focus {
				color: #fff;
				background: $secondary-color;
				border-color: $secondary-color;
			}
		}
		&.btn-light {
			color: #fff;
			border-color: #fff;
			&:hover, &:focus {
				background: $primary-color;
				border-color: $primary-color;
			}
			&.btn-alt:hover, &.btn-alt:focus {
				background: $secondary-color;
				border-color: $secondary-color;
			}
		}
	}
	&.btn-light:not(.btn-outline) {
		color: $primary-color;
		border-color: #fff;
		background-color: #fff;
		&:hover, &:focus {
			color: #fff;
			background: $primary-color;
			border-color: $primary-color;
		}
		&.btn-alt {
			color: $secondary-color;
			&:hover, &:focus {
				color: #fff;
				background: $secondary-color;
				border-color: $secondary-color;
			}
		}
	}
	&.btn-center{
		margin:0 auto;
		width: auto;
		min-width: 160px;
	}
	&.btn-lg {
		padding:16px 35px;
		font-size: 16px;
		@include border-radius($radius-btn);
	}
	&.btn-md {
		padding:8px 20px;
	}
	&.btn-sm {
		padding:3px 12px 4px;
		font-weight: 400;
	}
	&.round {
		@include border-radius(60px);
	}
}
.btn-link{
	color: $primary-color;
	transition: all .4s ease-in-out;
	&:hover {
		color: $primary-color;
	}
	&.link-arrow, &.link-arrow-sm {
		position: relative;
		padding-right: 48px;
		&:after, &:before {
			content: "";
			display: block;
			position: absolute;
			transition: all 0.4s ease-in-out;
		}
		&:before {
			top: 50%;
			right: 9px;
			width: 9px;
			height: 9px;
			transform-origin: 50% 50%;
			transform:  translate(0, -4px) rotate(45deg);
			box-shadow: inset -1px 1px 0 0 currentColor;
		}
		&:after {
			top: 50%;
			right: 8px;
			width: 30px;
			height: 1px;
			transform:  translate(0, 0);
			background-color: currentColor;
		}
		&:hover {
			text-decoration: none;
			&:before {
				transform:  translate(8px, -4px) rotate(45deg);
			}
			&:after {
				transform:  translate(8px, 0);
			}
		}
	}
	&.link-arrow-sm {
		padding-right: 36px;
		&:before {
			width: 7px;
			height: 7px;
			transform:  translate(0, -3px) rotate(45deg);
		}
		&:after {
			width: 18px;
		}
		&:hover:before {
			transform:  translate(8px, -3px) rotate(45deg);
		}
	}
}
.table-responsive{
	margin-bottom: $mgpd-md;
	&:last-child, .table{
		margin-bottom: 0;
	}
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	border-color: #ececec;
}
.table.bdr-top, .table.bdr-bottom {
	border-color: #ececec;
}
.form-control{
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	&:focus{
		-webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
	}
	&.error{
		border-color: #FF4346 !important;
	}
}
form label.error {
	display: none !important;
}
.form-results {
	display: none;
	font-size: 12px;
	margin: $mgpd-sm 0;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}
.gaps {
	clear: both;
	height: $mgpd;
	display: block;
	margin: 0;
	&.size-lg {
		height: $mgpd-lg;
	}
	&.size-md {
		height: $mgpd-md;
	}
	&.size-2x {
		height: ($mgpd * 2);
	}
	&.size-3x {
		height: ($mgpd + 10);
	}
	&.size-xs {
		height: $mgpd-xs;
	}
	&.size-sm {
		height: $mgpd-sm;
	}
	&.size-section {
		height: $space;
	}
}
.pd {
	&-x1 {
		padding: 10px;
	}
	&-x2 {
		padding: 20px;
	}
	&-x3 {
		padding: 25px;
	}
	&-x4 {
		padding: 35px;
	}
	&-x5 {
		padding: 45px;
	}
}
.no-pd, .pd-no {
	padding: 0;
}
.no-pd-t, .pd-no-t {
	padding-top: 0;
}
.no-pd-b, .pd-no-b {
	padding-bottom: 0;
}
.color-primary {
	color: $primary-color;
}
.color-secondary {
	color: $secondary-color;
}
.color-alternet {
	color: $alternet-color;
}
.bg-light {
	background: $bg-light;
}
.bg-lighter {
	background: rgba($bg-light, .6);
}
.bg-light-primary {
	background: rgba($primary-color, .06);
}
.bg-light-secondary {
	background: rgba($secondary-color, .06);
}
.bg-light-alternet {
	background: rgba($alternet-color, .06);
}
.bg-white {
	background: #fff;
}
.bg-dark {
	background: $bg-dark;
}
.bg-grey {
	background: $bg-grey;
}
.bg-primary {
	background: $bg-primary;
}
.bg-secondary {
	background: $bg-secondary;
}
.bg-alternet {
	background: $bg-alternet;
}
.bdr {
	border: 1px solid rgba(0,0,0,0.04);
	&-y {
		border-top: 1px solid rgba(0,0,0,0.04);
		border-bottom: 1px solid rgba(0,0,0,0.04);
	}
	&-x {
		border-left: 1px solid rgba(0,0,0,0.04); 
		border-right: 1px solid rgba(0,0,0,0.04);
	}
	&-top {
		border-top: 1px solid rgba(0,0,0,0.04);
	}
	&-bottom {
		border-bottom: 1px solid rgba(0,0,0,0.04);
	}
	&-none {
		border-color: transparent;
	}
}
.social {
	margin: 0;
	padding:0;
	list-style: none;
	li{
		display: inline-block;
		margin-left: 15px;
		&:first-child{
			margin-left: 0;
		}
	} 
}
.preload{
	display: none;
}
.navbar-toggle{
	margin-right: 0px;
}
.carousel-control{
	visibility:hidden;
	-webkit-transition: opacity 1s ease-out;
	-moz-transition: opacity 1s ease-out;
	-o-transition: opacity 1s ease-out;
	transition: opacity 1s ease-out;
	opacity: 0;
	width: 60px;
	&.left, &.right{
		background-image:none;
		filter:none;
	}
	.glyphicon-chevron-left, .glyphicon-chevron-right{
		width: 60px;
		height: 60px;
		padding-top: 0;
		@include border-radius(50px);
		font-family: 'FontAwesome';
		text-shadow: none;
		font-size: 44px;
		margin-top: -30px;
		&:hover{
			background:transparent;
		}
	}
	.glyphicon-chevron-left{
		left:10%;
		&:before{
			content:'\f104';
		}
	}
	.glyphicon-chevron-right{
		right:10%;
		&:before{
			content:'\f105';
		}
	}
}
.video-box {
	position: relative;
	margin-top: 40px;
	padding-top: 56%;
	iframe{
		border:1px solid #fbfbfb;
		@include border-radius();
		position: absolute;
		top: 0; 
		width: 100%;
		height: 100%;
		left: 0;
	}
}
.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-bottom: 1px solid #eee;
	border:none; 

}
.panel-default > .panel-heading {
    padding: 0;
    border-radius: 0;
    color: #212121;
    background-color: transparent;
    border:0px;
}
.panel-title {
    font-size: 20px;
    > a {
	    display: block;
	    padding: 15px;
	    text-decoration: none;
	}
}
.more-less {
    float: right;
    color: #212121;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #EEEEEE;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(50px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
/* ==========================================================================
    2.1 General / Common
   ========================================================================== */  
.content {
	&-section{
		margin-top: $mgpd-lg;
		&:first-child{
			margin-top: 0px;
		}
	}
	.list-style{
		li{
			position: relative;
			padding-left: 18px;
			margin-left: 0;
			margin-bottom: 12px;
			&:last-child {
				margin-bottom: 0;
			}
			&:before {
				font-family: 'FontAwesome';
				content:'\f105';
				color: $primary-color;
				position: absolute;
				left: 1px;
				top: 3px;
				font-size: 16px;
				line-height: 18px;
				.light &, .call-action:not(.dark) & {
					color: #fff;
				}
			}
		}
		&.dots li:before{
			content:'\f111';
			font-size: 8px;
		}
		&.list-sitemap li{
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
			font-weight: 400;
			ul{
				margin-top: 5px;
				li{
					margin-top: 3px;
					font-weight: 400;
				}
			}
		}
		&.style-v2 {
			li {
				margin-bottom: 2px;	
				&:before {
					color: lighten($heading-color, 40%);
					font-size: 6px;
				}
			}
		}
	}
	
	ol {
		margin-left: 50px;
		li{
			list-style: decimal;
			list-style: decimal-leading-zero;
			padding-left: 5px;
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.tab-custom {
	.nav-tabs{
		background: #ececec;
		border-bottom: none;
		display: table;
		width: 100%;
		li{
			display: table-cell;
			float: none;
		}
		li:last-child a{
			border-right-color: #ececec;
		}
		li > a{
			border: 1px solid #ececec;
			border-right-color: #f5f5f5;
			text-align: center;
			border-radius: 0px;
			padding: 15px 2px;
			margin: 0px;
			font-size: 16px;
			color: #383838;
			line-height: 24px;
			img{
				height: 60px;
				width: auto;
				margin-top: -5px;
			}
		}
		li.active > a, li.active > a:hover, li.active > a:focus{
			background:#f5f5f5;
			border-color: #f5f5f5;
		}
	}
	.tab-content{
		background: #f5f5f5;
		padding: 30px 20px 25px 20px;
		h4{
			color: $primary-color;
			font-weight: 700;
			font-size: 18px;
			margin-bottom:15px;
		}
		a{
			padding: 8px 16px;
			margin-top: 25px;
		}
	}
	&.tab-simple {
		.nav-tabs {
			background: none;
			li {
				display: inline-block;
				> a {
					padding: 12px 15px;
					border:1px solid #ececec;
					background-color: #ececec;
				}
				&.active > a {
					background-color: #fff;
					border-bottom-color: #fff;
				}
			}
		}
		.tab-content {
			background: none;
			border:1px solid #ececec;
		}
	}
}
.accordion {
	.panel {
		background-color: transparent;
		border-bottom: 1px solid rgba(0,0,0,0.10);
	}
	.panel:last-child {
		border-bottom: 0 none;
	}
	.panel + .panel {
		margin-top: 0;
	}
	.panel-title {
		position: relative;
		font-size: 1em;
		font-family: $font-primary;
		font-weight: 400;
		a {
			color: $primary-color;
			padding:16px 30px 17px 0;
			.plus-minus {
				opacity: 0.4;
			}
			&.collapsed {
				color: $default-color;
				&:hover {
					opacity: 0.7;
					.plus-minus {
						opacity: 0.4;
					}
				}
				.plus-minus {
					opacity: 0.7;
					span:before {
						transform:rotate(-180deg);
					}
					span:after{
					transform:rotate(0deg);
					}
				}
			}
			&:hover {
				opacity: 1;
				.plus-minus {
					opacity: 1;
				}
			}
		}
	}
	.panel-body {
		padding-right: 0;
		padding-left: 0;
		padding-bottom: 30px;
	}
	.plus-minus {
		opacity: 0.8;
		cursor: pointer;
		transition: opacity 350ms;
		display: block;
		position: absolute;
		right: 0;
		top: 50%;
		height: 18px;
		width: 18px;
		margin-top: -9px;
		span {
			position: relative;
			height: 100%;
			display: block;
			&:before, &:after {
				content: "";
				position: absolute;
				background: #666;
				transition: transform 100ms;
			}
			&:before {
				height: 2px;
				width: 100%;
				left: 0;
				top: 50%;
				margin-top: -1px;
				transform:rotate(180deg);
			}
			&:after {
				height: 100%;
				width: 2px;
				top: 0;
				left: 50%;
				margin-left: -1px;
				transform:rotate(90deg);
			}
		}
		&:hover {
			opacity: 0.3;
		}
	}
	&.filled {
		.panel:last-child {
				border-bottom: 0;
		}
		.panel-title { 
			background-color: #f5f5f5;
			a {
				background: #ececec;
				padding-left: 20px;
				color: $primary-color;
				&.collapsed {
					background-color: transparent;
					color: $default-color;
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
		.panel-body {
			padding-right: 20px;
			padding-left: 20px;
			padding-bottom: 20px;
		}
		.plus-minus {
			right: 20px;
		}
	}
}
.panel-group:last-child {
	margin-bottom: 0;
}
.carousel {
	height: 100%;
	.stky-banner{
		position: absolute;
		top: 0;
	}
	.carousel-inner, .item, .active {
	    height: 100%;
	}
}
.position{
	position: static;
}
.img-wrapper{
	position: absolute;
	bottom: 0px;
	max-width: 520px;
}
.contact-list{
	margin-top: $mgpd-md;
	&:first-child {
		margin-top: 0;
	}
	li{
		margin-bottom: $mgpd;
		padding-bottom: 0;
		&:last-child{
			margin-bottom: 0px;
		}
		.fa{
			color: $primary-color;
			font-size: 24px;
			display: block;
			width: 30px;
			float: left;
		}
		span{
			margin-left: 40px;
			display:block;
			line-height: 1.4;
		}
	}
}
.icon-box {
	width: 48px;
	height: 48px;
	display: inline-block;
	vertical-align: middle;
	color: $primary-color;
	transition: all 0.4s;
	text-align: center;
	font-size: 1.25em;
	line-height: 50px;
	margin: 2px 0;
	border:1px solid transparent;
	.light & {
		color: #fff;
	}
	&.left{
		text-align: left;
	}
	&.size-md {
		width: 60px;
		height: 60px;
		font-size: 1.875em;
		line-height: 64px;
	}
	&.size-sm {
		width: 24px;
		height: 24px;
		font-size: 14px;
		line-height: 26px;
		span.icon {
			transform: scale(0.5) translate(-14px, -6px)
		}
	}
	&.size-lg {
		width: 90px;
		height: 90px;
		font-size: 3em;
		line-height: 94px;
		&.sqaure, &.round, &.circle {
			border-width: 2px;
		}
	}
	&.sqaure, &.round, &.circle {
		border-color: $primary-color;
		.light & {
			border-color: #fff;
		}
	}
	&.round {
		@include border-radius();
	}
	&.circle {
		@include border-radius(50%);
	}
	&.icon-bg {
		background-color: $primary-color;
		color: #fff;
	}
	&.icon-bg-alt {
		background-color: $secondary-color;
		color: #fff;
	}
	&.light {
		color: #fff;
		&.sqaure, &.round, &.circle {
			border-color: #fff;
		}
		&.icon-bg {
			background-color: #fff;
			color: $primary-color;
		}
	}
	&+h4, &+h3 {
		margin-top: $mgpd;
	}
	span.icon {
		vertical-align: middle;
		margin-top: -5px;
	}
	&.style-s1, &.style-s2  {
		@include border-radius(50%);
		background-color: $primary-color;
		color: #fff;
	}
	&.style-s2 {
		background-color: $secondary-color;
	}
	&.style-s3, &.style-s4 {
		@include border-radius();
		border-color: $primary-color;
		color: $primary-color;
	}
	&.style-s4 {
		@include border-radius();
		border-color: $secondary-color;
		color: $secondary-color;
	}
}
.feature-intro {
	.row + .row {
		margin-top: $mgpd-lg;
	}
	a.btn-link {
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 2px;
		text-transform: uppercase;
		display: inline-block;
		vertical-align: middle;
		color: $primary-color;
	}
}
.owl-dots{
	text-align: center;
	margin-top: 20px;
	.owl-dot{
		height: 10px;
		width: 10px;
		border-radius: 50%;
		border: 1px solid $primary-color;
		display: inline-block;
		margin: 0 5px;
		&.active{
			background: $primary-color;
			.logo-carousel & {
				background: #cdcdcd;
			}
		}
		.logo-carousel & {
			border-color: #cdcdcd;
		}
	}
}
.owl-nav{
	/* @v110*/
	.owl-prev, .owl-next{
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		border-radius: 50%;
		text-align: center;
		height: 44px;
		width: 44px;
		text-indent:-999em; 
		text-align:left;
		opacity: .8;
		background-repeat: no-repeat; 
		background-position:50% 50%; 
		background-size:12px auto;
		&:hover {
			opacity: 1;
		}
		> .fa {
			display: none;
		}
	}
	.owl-next{ 
		right: -52px;
		background-image:url(../image/arrow-next-g.png);
		.light & {
			background-image:url(../image/arrow-next-w.png);
		}
	}
	.owl-prev{
		left: -52px;
		background-image:url(../image/arrow-prev-g.png);
		.light & {
			background-image:url(../image/arrow-prev-w.png);
		}
	}
}
/* ==========================================================================
    3.0 Header/Navbar
   ========================================================================== */
.site-header {
	.navbar {
		border-radius: 0;
		border: 0 none;
		min-width: 300px;
		padding: 12px 0;
		color: $header-color;
		background: $header-bgcolor;
		margin: 0;
	}
	.navbar-brand{
		height: auto;
		padding: 0;
		img {
			width: auto;
			max-width: 100%;
			max-height: 60px;
		}
	}
	.navbar-header .quote-btn {
		display: none;
	}
	.navbar-collapse {
		padding: 0;
		background-color: transparent;
		color: $nav-color;
	}
	.logo-light {
	    display: none;
	}
	.quote-btn {
		padding: 0;
		display: inline-block;
		.btn {
			font-family: $font-secondary;
			padding: 6px 25px;
			font-size: 12px;
			line-height: 24px;
			font-weight: 700;
			text-transform: uppercase;
			color: $header-btn-color;
			background-color: $header-btn-bgcolor;
			border: 2px solid $header-btn-bgcolor;
			margin: 0;
			vertical-align: middle;
			&:hover {
				border-color: $header-btn-bgcolor-alt;
				color:#fff;
				background-color: $header-btn-bgcolor-alt; 
			}
			&.btn-outline {
				background: transparent;
				&:hover {
					background: $header-btn-bgcolor;
					border-color: $header-btn-bgcolor;
				}
			}
		}
	}
}
// TopBar
.topbar {
	padding: 6px 0;
	color: $topbar-color;
	background: $topbar-bgcolor;
	border-bottom: 1px solid rgba(0,0,0,0.06);
	font-size: 13px;
	line-height: 1;
	.top-aside {
		> p {
			margin: 0 10px 0 0;
		}
		> *  {
			float: left;
		}
	}
	.top-left {
		float: left;
		max-width: 44%;
	}
	.top-right {
		float: right;
		max-width: 55%;	
	}
	.top-contact {
		.fa {
			border: 0 none;
			width: auto;
			height: auto;
			line-height: 27px;
		}
		span{
			font-size: 1.1em;
			line-height: 30px;
		}
	}
	.top-nav li a {
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
		transition: color .3s;
	}
	.quote-btn .btn {
		padding:3px 15px;
		vertical-align: top;
	}
	.social {
		&:not(:last-child) {
			margin-right: 25px;
		}
		li a {
			line-height: 30px;
			font-size: 16px;
			color: $primary-color;
		}
	}
}
.top-nav {
	margin: 0; 
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		vertical-align: middle;
		&:first-child {
			margin-left: -10px;
		}
	}
	a{
		padding:6px 10px;
		color: inherit;
		font-size: 13px;
		line-height: 24px;
		&:hover, &:focus {
			color: $primary-color;
		}
	}
}
.top-contact {
	margin: 0;
	list-style: none;
	font-size: 13px;
	line-height: 30px;
	color: inherit;
	.fa {
		height: 30px;
		width: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 50%;
		background: transparent;
		border: 1px solid $primary-color;
		color: $primary-color;
		font-size: 18px;
		margin-right: 5px;
		vertical-align: middle;
	}
	span{
		font-size: 1.25em;
		font-weight: 400;
	}
	li{
		margin-left: 25px;
		float: left;
		line-height: 1;
		&:first-child {
			margin-left: 0;
		}
	}
	a {
		color: inherit;
		&:hover {
			color: $primary-color;
		}
	}
}
.header-top {
	margin-top: 10px;
	margin-right: -15px;
	float: right;
	> * {
		float: left;
		margin-left: 25px;
	}
	> *:first-child {
		margin-left: 0;
	}
	.top-contact {
		line-height: 40px;
		.fa {
			height: 40px;
			width: 40px;
			line-height: 38px;
		}
		span {
			vertical-align: middle;
		}
	}
	.quote-btn .btn {
		padding: 12px 22px;
		margin-top: -4px;
	}
	.social {
		li a {
			line-height: 40px;
			font-size: 16px;
			color: $primary-color;
		}
		&.social-s2 li:not(:first-child) {
			margin-left: 4px;
		}
		&.social-s2 li a {
			display: inline-block;
			height: 40px;
			width: 40px;
			line-height: 38px;
			text-align: center;
			border-radius: 50%;
			background: $primary-color;
			border: 1px solid $primary-color;
			color: #fff;
			font-size: 16px;
			vertical-align: middle;
			&:hover {
				opacity: .5;
			}
		}
	}
}
// MainNav
.navbar-nav {
	width: 100%;
	> li {
		> a {
			background: transparent; 
			text-transform: uppercase;
			color: inherit;
			font-family: $nav-font-family;
			font-weight: $nav-font-weight;
			font-size: $nav-font-size;
			line-height: 20px;
			padding: 14px 16px;
			transition: background 350ms, color 350ms, border-color 350ms;
		}
		&.active > a, > a:hover, > a:focus {
			color: $nav-color-over;
			background: transparent;
		}
		> .dropdown-menu .dropdown-menu {
			top: 0;
			margin-top: 0;
		}
		&:hover > .dropdown-menu {
			top: 100%;
			margin-top: 0;
		    opacity: 1;
		    visibility: visible;
		}
		&.quote-btn {
			margin-left: 12px;
			float: right;
			.btn {
				padding: 8px 24px;
				font-size: $nav-font-size;
				font-weight: 700;
			}
		}
	}
	.dropdown-menu{
		background: #fff;
		border: 0px;
		border-radius:0;
		padding: 0px;
		left: 0;
		top: -999em;	
		display: block;
		opacity: 0;
		width: 240px;
		visibility: hidden;
		transition: opacity .3s, visibility .4s;
		.dropdown-menu{
			left: 240px;
			opacity: 0;
		}
		li {
			position: relative;
			a{
				padding: 10px 25px 10px 15px;
				text-transform: inherit;
			}
			&:hover {
				.dropdown-menu{
					opacity: 1;
					visibility: visible;
					height: auto;
				}
			}
			&.dropdown > a:after {
				content: "\f105";
				display: block;
				position: absolute;
				top: 50%;
				right: 8px;
				opacity: .5;
				margin-top: -7px;
				height: 14px;
				width: 12px;
				text-align: center;
				font-size: 13px;
				line-height: 14px;
				font-family: "FontAwesome";
			}
		}
		&.active > a, li > a:hover, li > a:focus {
			color: $primary-color;
		}
		> .active > a, > .active > a:focus, > .active > a:hover {
			color: $primary-color;
			background-color:#f5f5f5; 
		}
	}
	.label {
		margin-top: -3px;
		vertical-align: middle;
		display: inline-block;
		font-family: $font-primary;
		margin-left: 3px;
		font-weight: normal;
		padding: 4px 6px 3px;
	}
	.caret{
		margin-top: 0;
		vertical-align: middle;
		transform: translateY(-50%);
	}
}
.header-s1{
	.navbar {
		padding: 0;
		&-nav {
			> li > a {
				padding-top: 26px;
				padding-bottom: 26px;
			}
			> li.quote-btn {
				margin-top: 12px;
			}
		}
	}
	.navbar-brand {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.navbar-collapse {
		margin-right: -15px;
		float: right;
		width: auto;
	}
	&.is-transparent{
		color: #fff;
		&:after{
			position: absolute;
			content: '';
			display: block;
			top: -200px;
			left: 0;
			right: 0;
			height: 375px;
			opacity: .5;
			background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		}
		.topbar, .navbar{
			background: transparent;
			position: absolute;
			width: 100%;
			z-index: 9;
			color: #fff;
		}
		.topbar + .navbar {
			margin-top: 43px;
		}
		.topbar {
			border-color: rgba(255,255,255,.1);
			a:not(.btn):hover {
				color: #fff;
				opacity: .5;
			}
		}
		.navbar-nav > li > a:not(.btn):after {
			content: "";
			position: absolute; 
			left: 12px;
			bottom: 15px;
			width: 18px;
			height: 2px;
			background-color: $primary-color;
			opacity: 0;
			transition: all .4s;
		}
		.navbar-nav > li.active > a:after, .navbar-nav > li > a:hover:after {
			opacity: 1;
		}
		.navbar-nav > li.active > a, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
			color: #fff;
		}
		.navbar-collapse, .top-contact .fa {
			color: #fff;
		}
		.navbar-brand {
			.logo-light {
				display: block;
			}
			.logo-dark {
				display: none;
			}
		} 
		&.has-fixed {
			.topbar + .navbar {
				margin-top: 0;
			}
			.navbar {
				background: #fff;
				color: $nav-color;
			}
			.navbar-nav > li.active > a, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus {
				color: $primary-color;
			}
			.navbar-nav > li.quote-btn .btn {
				color: #fff;
			}
			.navbar-nav > li > a
			.navbar-nav > li > a:not(.btn)::after{
				background-color: $primary-color;
			}
			.navbar-collapse {
				color: $nav-color;
			}
			.banner {
				margin-top: 0;
			}
			.navbar-brand {
				.logo-light {
					display: none;
				}
				.logo-dark {
					display: block;
				}
			} 
		}
	}
	&.has-fixed {
		.navbar {
			position: fixed;
			width: 100%;
			z-index: 9;
			top: 0;
			box-shadow: 0 1px 5px rgba(0, 0, 0, 0.06);
		}
		.banner {
			margin-top: 76px;
		}
	}
	&.header-dark{
		.topbar {
			background: darken($primary-color, 5%);
			color: #fff;
			.icon, .fa, a:hover {
				color: $secondary-color;
			}
		}
		.navbar{
			background: $primary-color;
		}
		.navbar-nav > li > a, .navbar-nav > li.active > a, .navbar-nav > li > a:hover, .navbar-nav > li > a:focus{
			color: #fff;
		}
		.navbar-nav li.quote-btn .btn:hover {
			background: $alternet-color;
		}
		.navbar-brand  {
			.logo-dark {
				display: none;
			}
			.logo-light {
				display: block;
			}
		}
	}
}
// Breadcrumb
.breadcrumb {
    background-color: transparent;
    padding: 0;
    font-size: 12px;
	line-height: 1;
	> li {
		margin: 6px 0;
	}
	a {
		color: inherit;
		text-decoration: none;
		transition: all 0.4s;
	}
	>.active{
		opacity: 0.6;
		color: inherit;
	}
	li:not(.active) a:hover {
		color: $secondary-color;
	}
	>li+li:before {
		color: inherit;
		opacity: 0.5;
		padding: 0 6px 0 3px;
	}
	&.breadcrumb-arrow>li+li:before {
		content: "\00bb";
	}
	&.breadcrumb-dash>li+li:before {
		 content: "\2013";
	}
	&.breadcrumb-dots>li+li:before {
		content: "\2022";
	}
}

/* ==========================================================================
    4.0 Banner/Slider
   ========================================================================== */
.banner-text{
	color: $default-color;
	position: relative;
	padding-top: $space-md;
	padding-bottom: $space-md;
	z-index: 1;
	font-size: $font-size;
	&.wide-md {
		max-width: 580px;
	}
	&.wide-sm {
		max-width: 420px;
	}
	&.wide-lg {
		max-width: 720px;
	}
	&.pos-center{
		margin: 0 auto;
	}
	&.pos-left{
		float: left;
	}
	&.pos-right{
		float: right;
	}
	&.al-center:not(.pos-center), &.center:not(.pos-center) {
		margin: 0 auto;
	}
	&.light{
		color: #fff;
	}
	&.dark{
		color: darken($default-color, 30%);
		&+.page-breadcrumb {
			color: darken($default-color, 30%); 
		}
	}
	h1, h2{
		color: inherit;
		font-size: 2.5em;
		strong {
			color: $primary-color;
		}
	}
	.with-line:after {
		background-color: #fff;
	}
	.animated {
		animation-delay: 350ms;
		animation-duration: 1s;
	}
	.banner-cta:not(:first-child) {
		margin-top: 25px;
	}
	.btn {
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	.btn + .btn {
		margin-left: 15px;
	}
	.page-title{
		color: inherit;
		font-size: 2.5em;
		font-weight: 400;
		margin-bottom: 10px;
	}
}
.banner-slider{
	height: 500px;
	&.slider-large {
		height: 600px;
	}
	.carousel-inner {
        .item {
            transition-property: opacity;
        }
        .item, .active.left,.active.right {
            opacity: 0;
        }
        .active, .next.left, .prev.right {
            opacity: 1;
        }
        .next, .prev, .active.left, .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }
	.banner-content{
		position: relative;
		top: 50%;
		transform: translate(0, -50%);
	}
	.banner-text{
		width: 100%;
		max-width: 600px;
		position: relative;
		padding-top: 0;
		padding-bottom: 0;

	}
	.banner-boxed {
		padding: 35px 35px 40px;
		background: rgba(0,0,0,0.6);
	}
	&:hover > .carousel-control{
		opacity: 1;
		visibility: visible;
	}
	.fill {
	    width: 100%;
	    height: 100%;
	    background-position: center center;
	    background-size: cover;
	}
}
.banner-intro{
	color: #fff;
	.container > .content {
		padding-top: $mgpd-sm;
		padding-bottom: $mgpd-sm;
	}
	.banner-text{
		font-size: ($font-size + 2);
		padding-top: ($space + 70);
		padding-bottom: ($space + 70);
		.is-transparent & {
			margin-top: 60px;
		}
	}
}
.banner-static {
	color: #fff;
	position: relative;
	width: 100%;
	padding: 15px 0;
	min-height: 320px;
	&:not(.has-bg-image) {
		background-color: $primary-color;
	}
	.banner-text:not(.dark){
		color: #fff;
	}
	.banner-cpn {
		padding-top: $space-md;
		padding-bottom: $space-md;
		position: absolute;
		width: 100%;
		left: 0;
		top: 50%;
		z-index: 5;
		transform: translateY(-50%);
		.is-transparent & {
			margin-top: 50px;
		}
		.content {
			position: relative;
		}
		.banner-text {
			width:100%;
			padding-top: 0;
			padding-bottom: 0;
			&:not([class*="pos-"]) {
				float: left;
			}
			&:not([class*="wide-"]) {
				max-width: 550px;
			}
		}
		.page-breadcrumb {
			max-width: 400px;
			width:100%;
			position: absolute;
			right: 0;
			text-align: right;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	&.banner-small {
		height: 220px;
		min-height: inherit;
	}
	&.banner-intro{
		min-height: 550px;
	}
	&.only-pg-title {
		min-height: 160px;
	}
	&.bg-grey, &.bg-light {
		background: $bg-grey;
		.banner-text {
			color: $default-color;
		}
		.light {
			color: #fff;
		}
		.dark {
			color: $default-color;
		}
	}
	.imagebg {
	    position: absolute;
	    z-index: 0;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    -ms-border-radius: 0;
	    border-radius: 0;
	}
	.is-transparent & {
		min-height: 400px;
	}
	&.has-bg-image .container {
		position: static;
	}
}
.navbar-fixed-top + .banner-static {
	padding: 145px 0 15px;
}
/* ==========================================================================
    5.0 CTA / Call-Action
   ========================================================================== */   
.call-action{
    color: #fff;
	background-color: $primary-color;
	background-repeat: no-repeat;
    background-position:center center;
    background-size: cover;
    position: relative;
    .cta-block {
		padding: $space-sm 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: table;
		> .cta-sameline {
			padding: 0;
		}
	}
	h1, h2, h3, h4{
		font-weight: 400;
		color: inherit;
		line-height: 1.5;
		margin: 15px 0;
	}
	h4 {
		font-weight: 300;
	}
	p {
		margin: 15px 0;
	}
	h1, h2 {
		font-weight: 700;
		font-size: 1.875em;
	}
	a:not(.btn) {
		color: inherit;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.cta-num {
		font-size: 1.25em;
		line-height: 28px;
		font-weight: 300;
		strong {
			font-weight: inherit;
			font-size: 1.7em;
			display: inline-block;
			letter-spacing: 1px;
			padding-left: 5px;
		}
		a:not(.btn) {
			text-decoration: none;
			&:hover {
				box-shadow: 0 1px 0 #fff;
			}
		}
	}
	.btn{
		min-width: 160px;
		font-size: 13px;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-top: $mgpd;
		&:first-child {
			margin-top: 0;
		}
		&.btn-light {
			color: $primary-color;
			&:hover {
				color: #fff;
				background-color: $secondary-color;
				border-color: $secondary-color;
			}
		}
		&.btn-outline:hover {
			background-color: #fff;
		}
	}
	&.bg-grey {
		background-color: #f0f0f0;
	}
	&.bg-light {
		background-color: #fbfbfb;
		border-top: 1px solid rgba(0,0,0,.04);
		border-bottom: 1px solid rgba(0,0,0,.04);
	}
	&.bg-grey, &.bg-light{
		color: $primary-color;
		p {
			color: $default-color;
		}
	}
	&.bg-primary {
		background-color: $primary-color;
	}
	&.bg-primary, &:not([class*='bg-']) {
		.btn:not(.btn-light) {
			color: #fff;
			background-color: $secondary-color;
			border-color: $secondary-color;
			&:hover, &:focus {
				color: $primary-color;
			}
		}
	}
	&.bg-secondary{
		background-color: $secondary-color;
		.btn:not(.btn-light) {
			color: #fff;
			background-color: $primary-color;
			border-color: $primary-color;
			&:hover, &:focus {
				color: $secondary-color;
			}
		}
		.btn-light {
			color: $secondary-color;
			&:hover {
				color: #fff;
				background-color: $primary-color;
				border-color: $primary-color;
			}
		}
	}
	&.bg-primary, &.bg-secondary, &:not([class*='bg-']) {
		.btn:not(.btn-light) {
			&:hover, &:focus {
				background-color: #fff;
				border-color: #fff;
			}
		}
	}
	&.bg-primary, &.bg-secondary, &:not([class*='bg-']) {
		.btn.btn-outline {
			border-color: #fff;
			color: #fff;
			background-color: transparent;
		}
	} 
	&.has-parallax {
		background-position: 50% 0;
		.cta-block {
			background: rgba(0, 0, 0, 0.7);
			padding: $space*1.5 0;
		}
		&.bg-primary .cta-block {
			background: rgba($primary-color, .7);
		}
		&.bg-secondary .cta-block {
			background: rgba($secondary-color, .7);
		}
		&.cta-large .cta-block {
			padding: $space*2.5 0;
		}
		&.cta-small .cta-block {
			padding: $space 0;
		}
	}
	&.has-bg {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		&:after {
			display: none;
		}
		.cta-block {
			background: rgba(0, 0, 0, 0.7);
		}
		&.cta-large .cta-block {
			padding: $space*2.5 0;
		}
		&.cta-small .cta-block {
			padding: $space 0;
		}
	}
	&.has-bg, &.has-parallax {	
		&.bg-primary .cta-block {
			background: rgba($primary-color, .7);
		}
		&.bg-secondary .cta-block {
			background: rgba($secondary-color, .7);
		}
		&.bg-light .cta-block {
			background: rgba($bg-light, .7);
		}
	}
	&.cta-small {
		.cta-block {
			padding: $mgpd-md 0;
		}
	}
	&.cta-large {
		.cta-block {
			padding: $space 0;
		}
	}
	&.cta-mini {
		.cta-block {
			padding: $mgpd-sm 0;
		}
		h2, h3, h4 {
			font-size: 1.25em;
			font-weight: 300;
		}
	}
	&.style-v2 {
		.cta-block {
			padding: $space*5 0 0;
		}
		.cta-content {
			padding: $mgpd-md 0;
			position: absolute;
			bottom: 0;
			left: 0; 
			right: 0;
			background-color: rgba($primary-color, .6);
		}
		&.bg-secondary .cta-content {
			background-color: rgba($secondary-color, .6);
		}
		&.bg-dark .cta-content {
			background-color: rgba(0,0,0,0.6);
		}
  		&.bg-light .cta-content {
			background-color: rgba(255,255,255,0.8);
		}
	}
	.cta-sameline {
		position: relative;
		padding-right: 260px;
		.content {
			padding-right: 260px;
			position: relative;
		}
		h1, h2, h3, h4, p {
			margin: 1px 0 3px;
		}
		.btn, .cta-num {
			top: 50%;
			right: 0;
			margin-top: 0;
			position: absolute;
			transform: translate(0, -50%);
			transform-style: preserve-3d;
		}
	}
}
/* ==========================================================================
    6.0 Page Content - About, Service etc
   ========================================================================== */
.timelines {
	margin-top: $mgpd-md;
	&:first-child {
		margin-top: 0;
	}
}
.timeline {
	padding-left: 120px;
	position: relative;
	& + .timeline{
		margin-top: $mgpd-md;
	}
	& + .timeline:after{
		top: -52px;
	}
	&:before, &:after{
		content: '';
		position: absolute;
	}
	&:before{
		background-color: $primary-color;
		@include border-radius(50%);
		box-shadow: 0 0 0 2px #fff;
		height: 10px;
		left: 76px;
		top: 6px;
		width: 10px;
		z-index: 1;
	}
	&:after{
		background-color: #e4e4e4;
		bottom: 0;
		left: 80px;
		top: 6px;
		width: 2px;
	}
	.tl-year{
		font-size: 1.5em;
		line-height: 24px;
		left: 0;
		margin-bottom: 0;
		position: absolute;
		top: 0;
		font-family: $font-primary;
	}
	.tl-title {
	    font-size: 1.25em;
	    line-height: 24px;
	    font-family: $font-primary;
	}
}
.content-box {
	@include border-radius();
	margin-bottom: $mgpd-md;
	padding: ($mgpd-md - 2);
	overflow: hidden;
	&:last-child {
		margin-bottom: 0
	}
	&:not([class*='bg-']) {
		background-color: $bg-light;
		.bg-light & {
			background-color: #fff;
		}
	}
	h4 {
		font-weight: 400;
	}
	h5 {
		margin-top: 0;
		& + ul {
			margin-top: -5px;
		}
	}
}

/* ==========================================================================
    7.0 Forms Style
   ========================================================================== */
.form-quote, .form-message{
	margin-top: $mgpd-md;
	&:first-child {
		margin-top: 0;
	}
	.form-control{
		height: 50px;
		@include border-radius();
		border: 1px solid #e1e1e1;
		background-color: #f8f8f8;
		.bg-light & {
			background-color: #fff;
		}
		&.txtarea{
			height: 150px;
		}
	}
	.form-group {
		margin-bottom: $mgpd-sm;
	}
	.form-field > p:first-child {
		margin-top: 5px;
		margin-bottom: 10px;
	}
	h3, h4 {
		margin-top: $mgpd-md;
		margin-bottom: 10px;
	}
	select{
		outline: none;
		width: 100%;
		height: 50px;
		background: #f8f8f8;
		@include border-radius();
		border: 1px solid #e9e9e9;
		padding-left: 10px;
		.bg-light & {
			background-color: #fff;
		}
	}
	input[type="checkbox"], input[type="radio"] {
		margin-right: 4px;
	}
	ul.form-field{
		margin-bottom: 5px;
		&:last-child{
			margin-bottom: 0px;
		}
	}
	.btn{
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
	}
}
.replyform {
	margin-top: $mgpd-md;
	&:first-child {
		margin-top: 0;
	}
}
.section-quoteform{
	background: $bg-grey;
	position: relative;
	overflow: hidden;
	.form-control{
		background-color: #fff;
	}
}
/* ==========================================================================
    8.0 Stats
   ========================================================================== */
.statistics{
	background-position: center center;
	background-size: cover;
	.stat-block{
		padding: 140px 0;
		min-height: 400px;
		background: rgba(0, 0, 0, 0.7);
	}
	.stat-wrapper{
		height: 100%;
		background: #f5f5f5;
		text-align: center;
		@include border-radius();
		
	}
	.stat-col{
		border-right: 1px solid #e3e3e3;
		padding: 40px 0 30px;
		&:last-child{
			border-right: none;
		}
		.stat-top{
			.fa{
				margin-right: 15px;
				margin-top: -15px;
				font-size: 36px;
				color: $primary-color;
			}
			.counter{
				font-family: $font-secondary;
				font-weight: 700;
				font-size: 36px;
				color: $secondary-color;
			}
		}
	}
}
/* ==========================================================================
    9.0 Testimonial
   ========================================================================== */
.quotes {
	margin-top: $mgpd-lg;
	&:first-child {
		margin-top: 0;
	}
	.quotes-text {
		padding:$mgpd-md 0;
		font-size: 1em;
	}
	&.quotes-single {
		.quotes-text {
			font-size: 18px;
			line-height: 1.875;
			margin-top: $mgpd;
		}
	}
	.profile{
		position: relative;
		text-align: center;
		img{
			width: 65px;
			@include border-radius();
			margin: 0 auto;
		}
		h5{
			font-weight: 700;
			font-size: 16px;
			margin-top: 15px;
			margin-bottom: 5px;
		}
		h6{
			font-weight: 400;
			font-family: $font-primary;
		}	
	}
}
.quotes-slider{
	margin-top: $mgpd;
	&:first-child {
		margin-top: 0;
	}
	&:hover .carousel-indicators {
		opacity: 1;
	}
	&.carousel {
		padding-bottom: 30px;
	}
	.carousel-indicators{
		bottom: 0;
		margin-top: 0;
		width: 60%;
		margin-left: -30%;
		line-height: 1;
		opacity: .5;
		transition: opacity .4s;
		li{
			display: inline-block;
			list-style: none;
			padding-bottom: 0;
			margin:0 3px;
			width: 10px;
			height: 10px;
			background-color: #ccc;
			border: 0px;
			&.active{
				background-color: $primary-color;
			}
		}
	}
}
.quotes-boxed {
	.quotes-text {
		background: rgba(255,255,255,0.9);
		@include border-radius();
		margin-bottom: $mgpd;
		padding: $mgpd-md;
	}
}
.testimonials-list {
	.quotes {
		border-bottom: 1px solid #eee;
		padding: 0 0 $mgpd-lg;
		margin-bottom: $mgpd-lg;
		&:last-child {
			margin-bottom: 0;
			border-bottom: 0;
			padding-bottom: 0;
		}
		.quotes-text {
			padding: 0;
		}
		.profile {
			text-align: left;
		}
	}
}
/* ==========================================================================
    10.0 Client Logo
   ========================================================================== */
.logo-item {
	background: #fff;
	@include border-radius();
	border: 1px solid rgba(0,0,0,0.04);
	text-align: center;
	padding: 10px;
	img {
		border:0 none;
		border-radius: border-radius();
		width: 100%;
		max-width: 190px;
	}
}
.style-v2 {
	.logo-item {
		background: none;
		border-color: transparent;
	}
}
.logos-lists {
	.row + .row {
		margin-top: $mgpd-md;
	}
	.logo-item img {
		box-shadow: 0 0 20px rgba(0,0,0,0.04);
	}
	&.logos-lists-flat {
		.logo-item {
			border-radius: 0;
			border:1px solid rgba(0,0,0,0.08);
			border-top: 0;
			border-left: 0;
			padding:10px 0;
			img {
				border-radius: 0;
				box-shadow: none;
				border: 0 none;
			}
		}
		.row {
			margin-right: 0;
			margin-left: 0;
		}
		.row + .row  {
			margin-top: 0;
		}
		.row:last-child .logo-item {
			border-bottom-color: transparent;
		}
		.col-sm-3, .col-sm-4, .col-md-3, .col-md-4 {
			padding:0;
			&:last-child .logo-item {
				border-right-color: transparent;
			}
		}
	}
}
.logo-carousel {
	.owl-controls {
		display: none;
	}
}
/* ==========================================================================
    11.0 Widgets Style
   ========================================================================== */  
.wgs-box {
	margin-bottom: $mgpd-lg;
	position: relative;
	overflow: hidden;
	&:last-child {
		margin-bottom: 0;
	}
	&:not(.boxed) .boxed {
		margin-top: 0;
		background-color: #fff;
		border: 1px solid #ececec;
		box-shadow: 0 0 4px rgba(0,0,0,0.02);
		@include border-radius();
		padding: 25px;
	}
	.wgs-content {
		position: relative;
		z-index: 2;
	}
	.wgs-heading {
		font-size: 1.5em;
	    font-weight: 400
	}
	&.has-bg:after {
		opacity: .7;
	}
	&.boxed {
		@include border-radius();
		padding: 25px;
		.wgs-heading {
			font-size: 1.375em;
			text-transform: inherit;
			color: $primary-color;
			font-weight: 400;
		}
		&:not([class*="bg-"]) {
			background-color: #fff;
			border: 1px solid #ececec;
			box-shadow: 0 0 4px rgba(0,0,0,0.02);
		}
	}
	h3, h4 {
		font-size: 1.5em
	}
	p + p, p + h3, p + h4 {
		margin-top: $mgpd-sm;
	}
	ul {
		list-style: none;
		margin: 0 0 10px;
		&:last-child {
			margin-bottom: 0;
		}
		li {
			padding-bottom: 10px;
			&:last-child {
				padding-bottom: 0;
			}
			ul {
				margin: 10px 0 0 35px;
				li {
					font-size: 0.875em;
					padding-left: 0;
					padding-bottom: 5px;
					list-style: disc;
					&:last-child {
						padding-bottom: 0;
					}
				}
			}
		}
	}
	ul.contact-list {
		li {
			padding-bottom: 0;
		}
	}
	ul.tag-list{
		margin-top: -10px;
		li{
			float: left;
			margin: 10px 10px 0 0;
			padding: 0;
			a{
				@include border-radius($radius);
				padding: 10px 20px;
				font-size: .875em;
				line-height: 1;
				color: $primary-color;
				border: 1px solid #e9e9e9;
				display: block;
				&:hover{
					background: #e9e9e9;
				}
			}
		}
	}
	ul.blog-recent{
		li{
			padding: $mgpd-sm 0;
			border-bottom: 1px solid #e9e9e9;
			overflow: hidden;
			&:first-child {
				padding-top: 0;
			}
			&:last-child{
				padding-bottom: 0px;
				border-bottom: 0 none;
			}
			img{
			float: left;
			max-width: 100px;
			margin-top: 2px;
			margin-right: 12px;
			height: auto;
			opacity: 1;
			transition: opacity 350ms;
			@include border-radius($radius);
		}
		a{
				display: inline-block;
				color: #777;
			}
			&:hover{
				a{
					color: $primary-color;
				}
				img{
					opacity: 0.5;
				}
			}
		}
	}

	a.btn {
		&.btn-full {
			margin-top: 10px;
			display: block;
		}
		.fa {
			margin-left: 6px;
		}
	}

	&.wgs-menus {
		ul li {
			font-weight: 600;
			a {
				color: $heading-color;
				&:hover, &:focus {
					color: $primary-color;
				}
			}
			ul li {
				font-weight: normal;
			}
		}
		ul.list > li  {
			padding: 12px 0;
			border-bottom: 1px solid rgba(0,0,0,0.08);
			&:first-child {
				padding-top: 0;
			}
			&.list-heading span {
				text-transform: uppercase;
				color: $heading-color;
				transition: color 0.4s;
			}
			&.list-heading a:hover span, &.list-current a span {
				color: $heading-color;
			}
			&.active > a, &.current > a {
				color: $primary-color;
			}
		}
		ul.list-grouped {
			border: 1px solid rgba(0,0,0,0.08);
			padding: 0;
			background-color: #fff;
			box-shadow: 0 0 4px rgba(0,0,0,0.02);
			overflow: hidden;
			@include border-radius();
			> li {
				padding: 0;
				a {
					padding: 12px 25px;
					display: block;
				}
				&.list-heading {
					padding: 0;
					> a {
						padding: 0;
					}
					span {
						padding: 12px 25px;
						display: block;
						background-color: #f8f8f8;
					}
				}
				&:last-child {
					border-bottom: 0 none;
				}
			}
			li {
				ul {
					margin: 0;
					li {
						padding-bottom: 0;
						&.active a, &.current a{
							color: $primary-color;
							position: relative;
							&:before {
								position: absolute;
								width: 3px;
								height: 100%;
								content: "";
								left: 0;
								top: 0;
								background: $primary-color;
							}
						}
						list-style: none;
						border-top: 1px solid #e9e9e9;
						a {
							padding: 12px 25px 11px;
							display: block;
						}
					}
				}
			}
		}
	}
	&.wgs-search {
		.form-group{
			height: 50px;
			position: relative;
			border: 1px solid #e9e9e9;
			@include border-radius();
			&:last-child {
				margin-bottom: 0;
			}
			.form-control{
				height: 100%;
				background: #fafafa;
				font-size: 16px;
				border: 0px;
			}
			button.search-btn{
				position: absolute;
				right: 0px;
				height: 100%;
				top: 0px;
				background: #eee;
				width: 50px;
				color: $primary-color;
				border: 0px;
				border-bottom-right-radius: $radius;
				border-top-right-radius: $radius; 
				transition: color 0.4s;
				&:hover {
					color: $secondary-color;
				}
			}
		}
	}
	&.wgs-quoteform {
		background-color: #f8f8f8;
		padding: 25px;
		@include border-radius();
		.form-control {
			background: #fff;
		}
	}
}
/* ==========================================================================
    12.0 Footer / Copyrights
   ========================================================================== */
.footer-widget{
	background: $footer-bgcolor;
	color: $footer-color;
	font-size: 14px;
	line-height: $font-height;
	a{
		color:inherit;
		text-decoration: none;
		&:hover, &:focus {
			color: $primary-color;
		}
	}

	.wgs-footer {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.wgs-title {
		font-size: 16px;
		color: $primary-color;
		font-weight: 700;
		text-transform: uppercase;
		margin:0 0 12px;
	}
	.wgs-content {
		ul {
			list-style: none;
			margin: 0;
			li {
				padding-bottom: 8px;
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
	.contact-info {
		.fa {
			float: left;
			color: #747474;
			font-size: 24px;
			margin-top: 5px;
		}
		span{
			margin-left: 40px;
			display:block;
		}
		li:not(:last-child){
			padding-bottom: $mgpd-sm;
		}
	}
	&.style-v2 {
		background: lighten($footer-bgcolor, 84%);
		border-top:1px solid rgba($footer-bgcolor, .06);
		color: $default-color;
		.wgs-title {
			color: $heading-color;
		}
	}
	.wgs-menu {
		.menu li {
			padding-left: 12px;
			position: relative;
			a:before {
				font-family: 'FontAwesome';
				content: "\f105";
				position: absolute;
				left: 0;
				margin-top: 2px;
				line-height: 16px;
				font-size: 16px;
				color: $primary-color;
				display: inline-block;
				transition: left .3s;
			}
			&:hover {
				a:before {
					left: 3px;
				}
			}
		} 
	}
}
.copyright{
	background: $copyright-bgcolor;
	padding: 25px 0;
	color: $copyright-color;
	font-size: 13px;
	line-height: 1.4;
	a {
		color: inherit;
		text-decoration: none;
		&:hover, &:focus {
			color: lighten($primary-color, 10%);
		}
	}
	.sep {
		color: inherit;
		padding: 0 5px;
	}
	&.style-v2 {
		background: lighten($copyright-bgcolor, 95%);
		border-top:1px solid rgba($copyright-bgcolor, .06);
		color: darken($copyright-color, 65%);
	}
}
/* ==========================================================================
    13.0 Blog / News
   ========================================================================== */
.post {
	.post-meta{
		width: 100%;
		.pub-date{
			color: #999;
			font-size: 12px;
			text-transform: uppercase;
		}
		&:not(:first-child) {
			margin-bottom : $mgpd-sm;
		}
		li{
			display: inline-block;
			font-size: 13px;
			margin-right: 40px;
			&:last-child{
				margin-right: 0px;
			}
			a {
				display: inline;
				color: #fff;
				transition: text-decoration 350ms;
				&:hover {
					text-decoration: underline;
				}
			}
			.fa {
				color: $primary-color;
				margin-right: 10px;
				font-size: 16px;
			}
			span{
				color: #fff;
			}
		}
	}
	.post-thumbs {
		position: relative;
		margin-bottom: $mgpd-sm;
		&:last-child {
			margin-bottom: 0;
		}
		img{
			@include border-radius();
			opacity:1;
			transition: opacity .4s;
		}
		a { 
			display: block;
			&:hover img{
				opacity: 0.7;
			}
		}
		.post-meta {
			position: absolute;
			margin: 0;
			bottom: 0;
			left: 0;
			@include border-radius(0);
			border-bottom-left-radius: $radius;
			border-bottom-right-radius: $radius;
		}
	}
	.post-entry {
		h2, h1 {
			font-size: 1.375em;
			font-weight: 400;
			a {
				color: $heading-color;
				&:hover {
					color: $primary-color;
				}
			}
		}
		a.btn {
			color: $secondary-color;
			background: none;
			border: 0 none;
			padding: 0;
			margin-top: -20px;
			margin-bottom: 0;
			text-transform: inherit;
			letter-spacing: inherit;
			font-size: .875em;
			font-weight: 400;
			&:hover {
				color: $primary-color;
			}
		}
	}
	&.post-loop {
		margin-top: $mgpd-lg;
	}
	&.post-single {
		.post-entry {
			h2, h1 {
				font-size: 1.875em;
			}
		}
	}
}
.post-col2{
	.post-loop:nth-child(2n+1){
		clear: left;
	}
}
.blog-posts {
	list-style: none;
	padding: 0;
	margin: 0;
	&:first-child, &.mgfix {
		margin-top: -$mgpd-lg;
	}
	&.mgno, &.nomg {
		margin-top: 0;
	}
	&.blog-post-alter{
		.post-thumbs{
			float: left;
			width: 35%;
		}
		.post-entry{
			width: 65%;
			margin-left: 38%;
			h2,  h1{
				font-size:1.2em;
				margin: 5px 0 15px 0;
			}
			.post-meta{
				background: transparent;
				padding: 0px;
				margin-bottom:0;
				li{
					font-size: 13px;
					span{
						color: #000;
					}
				}
			}
		}
	}
}
.pagination{
	margin: $mgpd-md 0 0 0;
	vertical-align: middle;
	&:first-child {
		margin-top: 0;
	}
	li a{
		color: #888;
		transition: .4s;
		border-color: #eee; 
	}
	& > li > a:focus, & > li > a:hover, > li > span:focus, & > li > span:hover, & > .active > a, & > .active > a:hover, & > .active > a:focus{
		background-color: #eee;
		border-color: #eee;
		color: $primary-color;
	} 
}
/* ==========================================================================
    14.0 FAQs css
   ========================================================================== */
.faqs-row:first-child {
	margin-top: -$mgpd-md;
}
.faqs-row.faqs-s1 + .faqs-row {
	margin-top: $mgpd-md;
}
.faqs {
	clear: both;
	margin-top: $mgpd-md;
	.faq-heading {
		font-family: $font-primary;
		font-size: 1.125em;
		color: $heading-color;
		font-weight: 400;
	}
	&.panel-group:last-child {
		margin-bottom: 0;
	}

}
.faqs-s2 .faqs {
	border: 1px solid rgba(0,0,0,0.08);
	padding: 30px;
	@include border-radius();
	.faq-heading {
		font-size: 1.125em;;
		font-weight: 400;
	}
	&.faqs-section-head {
		border: 0 none;
		padding: 0;
	}
}
.bg-light .faqs-s2 .faqs:not(.faqs-section-head), .bg-grey .faqs-s2 .faqs:not(.faqs-section-head) {
	background: #fff;
	box-shadow: 0 2px 4px rgba(0,0,0,0.03);
	border: 0 none;
}

/* ==========================================================================
    15.0 Feature Box css
   ========================================================================== */
.feature-row{
	&:first-child, &.mgfix {
		margin-top: -$mgpd-lg;
	}
	&.mgno, &.nomg {
		margin-top: 0;
	}
	&.row-overup {
		position: relative;
		z-index: 21;
		margin-top: -120px;
	}
	&:first-child, &.mgfix {
		z-index: 1;
		position: relative;
	}
}
.feature {
	margin-top: $mgpd-lg;
	overflow: hidden;
	position: relative;
	transform-style: preserve-3d;
	background: transparent;
	.fbox-photo, .fbox-content {
		margin-top: $mgpd;
		&:first-child {
			margin-top: 0
		}
	}
	.fbox-over {
		position: absolute;
		bottom: 0;
		width: 100%;
		z-index: 2;
		color: #fff;
		padding: 20px 25px;
		transition: all .4s;
		@include border-radius(0px 0px $radius $radius);
		background: rgba($primary-color, .85);
		.title, .fbox-content {
			margin: 0;
			-webkit-font-smoothing: antialiased;
		}
		.title + .fbox-content {
			margin-top: 10px;
			font-size: 0.875em;
		}
		h3, h4, h5 {
			color: inherit;
		}
		.btn {
			background-color: transparent;
			border-color: #fff;
			padding: 7px 16px;
			font-size: 12px;
			color: #fff;
			text-transform: uppercase;
			&:hover {
				background-color: #fff;
				color: $primary-color;
			}
			&:last-child {
				margin-bottom: 5px;
			}
		}
	}
	.fbox-photo {
		@include border-radius();
		position: relative;
		overflow: hidden;
		transform-style: preserve-3d;
		img {
			margin: 0;
			width: 100%;
			height: auto;
			border-radius: 0;
		}
		&.imagebg img {
			display: none;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: $primary-color;
			opacity: 0;
			z-index: 0;
			transition: opacity 0.5s;
		}
	}
	> a {
		display: block;
	}
	a:focus > .fbox-photo::after, a:hover > .fbox-photo::after {
		opacity: 0.5;
	}
	a.more-link {
		color: $primary-color;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: 400;
		text-decoration: no-repeat;
		&:hover {
			box-shadow: 0 1px 0 $primary-color;
		}
	}
	a.btn-link {
		color: $primary-color;
	}
	h3, h4 {
		color: $heading-color;
		font-size: 1.05em;
		font-family: $font-secondary;
		font-weight: 700;
		a {
			display: block;
		}
		&+.sub-lead, &+.lead {
			margin-top: -10px;
		}
		&.lead {
			font-size: 1.375em;
			opacity: 1;
			color: $heading-color;
		}
	}
	h3 a, h4 a {
		color: $heading-color;
		&:hover {
			color: $primary-color;
		}
	}
	.fa {
		-webkit-text-stroke: 1px #fff;
		text-stroke: 1px #fff;
	}
	&.light h3, &.light h4, &.light h3 a, &.light h4 a{
		color: #fff;
	}
	p, .sub-lead, .lead {
		color: $default-color
	}
	.sub-lead, .lead {
		font-size: 0.875em;
		font-weight: normal;
		opacity: .6;
	}
	&.bg-secondary {
		.fbox-over {
			background: rgba($secondary-color, .85);
			.btn:hover {
				color: $secondary-color;
			}
		}
	}
	&.bg-dark {
		.fbox-over {
			background: rgba(0,0,0,0.7);
			.btn:hover {
				color: $default-color;;
			}
		}
	}
	&.boxed[class*='bg-'] {
		padding: 25px;
		transition: transform .4s;
		@include border-radius();
		-webkit-transform: translateZ(0);
		-webkit-font-smoothing: antialiased
	}
	&.bg-primary{
		background-color: $primary-color;
		color: #fff;
		.fa {
			-webkit-text-stroke: 1px $primary-color;
			text-stroke: 1px $primary-color;
		}
	}
	&.bg-secondary{
		background-color: $secondary-color;
		color: #fff;
		.fa {
			-webkit-text-stroke: 1px $secondary-color;
			text-stroke: 1px $secondary-color;
		}
	}
	&.bg-primary-alt{
		background: lighten($primary-color, 9%);
		color: #fff;
		.fa {
			-webkit-text-stroke: 1px lighten($primary-color, 9%);
			text-stroke: 1px lighten($primary-color, 9%);
		}
	}
	&.bg-secondary-alt{
		background: lighten($secondary-color, 9%);
		color: #fff;
		.fa {
			-webkit-text-stroke: 1px lighten($secondary-color, 9%);
			text-stroke: 1px lighten($secondary-color, 9%);
		}
	}
	&.bg-primary, &.bg-secondary, &.bg-primary-alt, &.bg-secondary-alt {
		div > * {
			color: #fff;
		}
	}
}
a:hover > .feature.boxed {
	transform: translateY(-10px);
}
.feature {
	&-s1, &-s2 {
		.feature {
			@include border-radius();
			border: 1px solid rgba(0,0,0,0.08);
			padding: $mgpd;
			text-align: center;
			background-color: #fff;
			transition: background .4s, color 0.4s, opacity .3s, border-color .4s;
			.icon-box {
				margin-bottom: $mgpd-md;
				font-size: 24px;
				width: 60px;
				height: 60px;
				line-height: 58px;
				@include border-radius(50%);
			}
			.icon-box:last-child {
				margin-bottom: 0;
			}
		}
		[class*="col-"] > a {
			display: block;
			color: inherit;
		}
		[class*="md-3"] .feature, [class*="sm-3"] .feature {
			padding: $mgpd-md $mgpd-sm;
		}
	}
	&-s1 {
		.feature {
			h3, h4, h3 a, h4 a {
				color: $primary-color;
				transition: color .5s;
			}
			.icon-box {
				border-color: $primary-color;
				color: $primary-color;
			}
			&:hover {
				border-color: $primary-color;
				.icon-box {
					color: #fff;
					background-color: $primary-color;
				}
			}
		}
		&.hover-fill {
			.feature {
				h3, h4, h3 a, h4 a {
					color: $primary-color;
				}
				&:hover {
					background-color:  $primary-color;
					color: #fff;
					.icon-box {
						background-color: #fff;
						color: $primary-color;
					}
					h3, h4, h3 a, h4 a {
						color: #fff;
					}
				}
			}
		}
	}
	&-s2 {
		.feature {
			h3, h4, h3 a, h4 a {
				color: $secondary-color;
				transition: color .5s;
			}
			.icon-box {
				border-color: $secondary-color;
				color: $secondary-color;
			}
			&:hover {
				border-color: $secondary-color;
				.icon-box {
					color: #fff;
					background-color: $secondary-color;
				}
			}
		}
		&.hover-fill {
			.feature {
				h3, h4, h3 a, h4 a {
					color: $secondary-color;
				}
				&:hover {
					background-color:  $secondary-color;
					color: #fff;
					.icon-box {
						background-color: #fff;
						color: $primary-color;
					}
					h3, h4, h3 a, h4 a {
						color: #fff;
					}
				}
			}
		}
	}
	&-s4, &-s5 {
		&.boxed-filled {
			margin-left: 0;
			margin-right: 0;
			.container-fluid & {
				padding-top: 2px;
			}
			[class*='col-'] {
				padding: 0 1px;
			}
			.feature, .fbox-photo {
				@include border-radius(0px);
			}
		}
		.feature {
			@include border-radius();
			overflow: hidden;
			.fbox-over {
				@include border-radius(0px);
				p, .sub-lead, .lead {
					color: #fff;
				}
			}
			.fbox-photo {
				img {
					transition: all 1.2s;
				}
				&:after {
					display: none;
				}
			}
			&:hover, a:focus {
				.fbox-photo img {
					transform: scale(1.08);
				}
			}
		}
		&.off-text {
			.feature {
				.fbox-over {
					transform: translate(0, 100%);
					bottom: 60px;
					.fbox-content {
						opacity: 0;
						transition: opacity .4s;
					}
				}
				.fbox-photo img {
					transform: scale(1.01);
				}
				&:hover, > a:focus {
					.fbox-over {
						transform: translate(0, 0);
						bottom: 0;
						.fbox-content {
							opacity: 1;
							transition-delay: .1s;
						}
					}
				}
			}
		}
	}
	&-s5 {
		.feature {
			@include border-radius();
			overflow: hidden;
			.fbox-over {
				background: none;
				p, .sub-lead, .lead {
					color: #fff;
				}
			}
			.fbox-photo:after {
				display: block;
				opacity: 1;
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				z-index: 0;
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 75%);
			}
			&:hover .fbox-photo:after, &:focus .fbox-photo:after {
				opacity: 1;
			}
			&.bg-dark .fbox-over .btn:hover{
				color: $default-color;
			}
			&.bg-primary .fbox-photo:after {
				background: linear-gradient(to bottom, rgba($primary-color, 0) 0%, rgba($primary-color, .65) 75%);
			}
			&.bg-secondary .fbox-photo:after {
				background: linear-gradient(to bottom, rgba($secondary-color, 0) 0%, rgba($secondary-color, .65) 75%);
			}
		}
	}
}
.feature-album {
	h3, h4 {
		text-transform: inherit;
	}
}
.feature-imagebox {
	margin: 30px 0;
	background-color: #fff;
	color: $default-color;
	@include border-radius();
	padding: 25px 25px 30px;
	position: relative;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.block {
		position: relative;
		z-index: 1;
	}
	h3, h4 {
	    color: $primary-color;
	    text-transform: uppercase;
	}
	a > .fa {
		margin-left: 8px;
	}
	a.btn + .btn {
		margin-left: 10px;
	}
	&.light {
		background-color: #fff;
		color: $primary-color;
	}
	&.dark {
		background-color: #252525;
		color: #fff;
		*:not(.btn) {
			color: #fff;
		}
	}
	&.border {
		border: 1px solid #ececec;
	}
	&.border.dark {
		border: 1px solid #000;
	}
}
.boxed-w{
    background-color:#fff;
    .heading-box{
        padding: 50px 50px 0 30px;
            span{
            position: relative;
            padding:4px 0 4px 50px;
            display: block;
			font-size: 1.2em;
            &:before{
                position: absolute;
                content: '';
                display: block;
                height: 100%;
                width: 2px;
                left: 0;
                background-color: $primary-color;

            }
        } 
    }
}
.content-cards{
    position: relative;
    margin-top: -60px;
	[class*='col-'] {
		padding: 0;
	}
}
.cards {
	position: relative;
	overflow: hidden;
	height: 300px;
	.heading-sm-lead, .heading-md-lead, h3, h4, .heading-md, .btn, p {
		color: $heading-color;
		border-color: $heading-color;
	}
	.heading-sm-lead, .heading-md-lead, h3, h4 {
		font-weight: 400;
	}
}
.cards-thumb {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	border-radius: 0;
	img {
		border-radius: 0;
		margin: 0;
		padding: 0;
		position: absolute;
		width: auto;
		height: 100%;
		max-width: inherit;
		right: 0;
		.cards-v2 & {
			right: auto;
			left: 0;
		}
	}
}
.cards-light, .cards-dark {
	&:after{
		position: absolute;
		content: '';
		left:  -1px;
		height: 100%;
		right: -1px;
		top: 0;
	}
}
.cards-dark {
	&:after{
		background: linear-gradient(to right, rgba(255,255,255,.75) 0%,rgba(255,255,255,0) 90%)
	}
}
.cards-light {
	&:after{
		background: linear-gradient(to right, rgba(0,0,0,.6) 0%,rgba(0,0,0,0) 90%); 
	}
	.heading-sm-lead, .heading-md-lead, h3, h4, .heading-md, .btn, p {
		color: #fff;
		border-color: #fff;
	}
}
.cards-text {
	position: absolute;
	top: 50%;
	transform:translateY(-50%);
	z-index: 2;
	color: #fff;
	padding: 20px 25px;
	width: 80%;
	max-width: 280px;
	font-size: .875em;
}
.cards-v2 {
	&.cards-light:after {
		background: linear-gradient(to left, rgba(0,0,0,.75) 0%,rgba(0,0,0,0) 90%); 
	}
	&.cards-dark:after {
		background: linear-gradient(to left, rgba(255,255,255,.75) 0%,rgba(255,255,255,0) 90%)
	}
	.cards-text {
		right: 0;
		text-align: right
	}
}

/* ==========================================================================
    16.0 Team Member
   ========================================================================== */
.heading-section + .team-member-row {
	margin-top: -($mgpd * 2);
}
.team-member-row {
	&:first-child, &.mgfix {
		margin-top: -($mgpd * 2);
	}
	&.mgno, &.nomg {
		margin-top: 0;
	}
	&.mgtop {
		margin-top: -($mgpd * 2);
	}
}
.team-more-btn {
	text-align: center;
	&:not(:first-child) {
		margin-top: ($mgpd * 2);
	}
}
.teams.teams-alt {
	background: rgba(0,0,0,0.06);
}
.team-member {
	position: relative;
	overflow: hidden;
	margin-top: ($mgpd * 2);
	.team-photo {
		position: relative;
		img {
			margin: 0;
			width: 100%;
			height: auto;
		}
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: #000;
			opacity: 0;
			z-index: 0;
			@include border-radius();
			transition: opacity 0.5s;
		}
	}
	a:hover > .team-photo::after {
		opacity: 0.5;
	}
	.team-info {
		margin-top: 20px;
		&:first-child {
			margin-top: 0;
		}
		h4, .name {
			color: $heading-color;
			&+.sub-title {
				margin-top: -10px;
			}
		}
		.sub-title {
			font-size: .875em;
			color: lighten($heading-color, 40%);
			margin: -5px 0 0;
		}
		.sub-title + p {
			margin-top: $mgpd-sm;
		}
	}
	.social {
		margin-top: 10px;
		opacity: 1;
	}
}
.team-profile {
	margin-top: $mgpd-lg;
	overflow: hidden;
	&:first-child {
		margin-top: 0;
	}
	&+.team-profile {
		padding-top: $mgpd-sm;
	}
	.team-member {
		margin-top: 0;
		.name {
			color: $heading-color;
		}
	}
	.team-info {
		margin-top: 0;
		padding-left: 30px;
		hr, .hr {
			margin: $mgpd-md 0;
		}
	}
} 

/* ==========================================================================
    17.0 Gallery Style @v110
   ========================================================================== */ 
/* Gallery */
.gallery {
	display: block;
	&:before, &:after {
		content: "";
		display: table;
		clear: both;
	}
	&-col5 {
		.gallery-list > li {
			width: 20%;
		}
	}
	&-col4, &:not([class*="gallery-col"]) {
		.gallery-list > li {
			width: 25%;
		}
	}
	&-col3 {
		.gallery-list > li {
			width: 33.333333%;
		}
	}
	&-col2 {
		.gallery-list > li {
			width: 50%;
		}
	}
	&[class*="gallery-col"] {
		.gallery-list > li {
			float: left;
		}
	}
	&.gallery-filled {
		overflow: hidden;
		margin-bottom: 15px;
		.gallery-list {
			margin-left: 0;
			margin-right: 0;
			li {
				padding: 0;
			}
		}
		.gallery-item {
			@include border-radius(0);
		}
		.gallery-caption {
			text-align: center;
		}
	}
	&.hover-zoom {
		.gallery-item img {
			transform: scale(1.01);
		}
		a:hover > .gallery-item:after {
			opacity: 0.2;
		}
		a:hover > .gallery-item img {
			transform: scale(1.2);
		}
		&.has-filtered {
			.gallery-item:hover {
				& > img {
					transform: scale(1.2);
				}
				.gallery-item-link:after {
					opacity: .4;
				}
			}
		}
	}
	&.hover-fade {
		a:hover > .gallery-item:after {
			opacity: 0.4;
		}
	}
	&.hover-zoom, &.hover-fade {
		.gallery-item:hover, .gallery-item:focus {
			.gallery-item-caption {
				opacity: 0;
			}
		}
	}
}
.gallery.mgfix {
	margin-top: -(($mgpd * 2) - 15);
}
.gallery.mgtop, div[class*="wide-"] + .gallery {
	margin-top: ($mgpd * 2) -  15;
}
.gallery.gallery-md.mgfix {
	margin-top: -(($mgpd * 2) - 6);
}
.gallery.gallery-md.mgtop, div[class*="wide-"] + .gallery.gallery-md {
	margin-top: ($mgpd * 2) -  6;
}
.gallery-filled.mgfix {
	margin-top: -($mgpd * 2);
}
.gallery-filled.mgtop, div[class*="wide-"] + .gallery-filled {
	margin-top: $mgpd * 2;
}
.gallery:not(.gallery-filled) > .has-carousel {
	margin-top: 15px;
}
.gallery-list {
	list-style: none;
	margin-left: -15px;
	margin-right: -15px;
	padding: 0;
	li {
		padding: 15px;
		position: relative;
	}
	.gallery-md & {
		margin-left: -6px;
		margin-right: -6px;
		li {
			padding: 6px;
		}
	}
	&.has-carousel {
		margin-left: 0;
		margin-right: 0;
		li {
			padding: 0;
		}
		.owl-item {
			li {
				width: 100%
			}
		}
		&[data-dots="1"] {
			margin-bottom: $mgpd-md + 15;
		}
	}
	.owl-dots {
		position: absolute;
		bottom: -($mgpd-md + 15);
		margin-top: 0;
		width: 100%;
	}
}
.gallery-item {
	position: relative;
	overflow: hidden;
	display: block;
	@include border-radius();
	transform-style: preserve-3d;
	.gallery-square & {
		@include border-radius(0);
	}
	img {
		width: 100%;
		height: auto;
		transition: all 1s;
		@include border-radius(inherit);
	}
	> a {
		display: block;
		position: relative;
		z-index: 4;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #000;
		opacity: 0;
		z-index: 0;
		transition: opacity 0.5s;
		@include border-radius(inherit);
	}
	&:hover, &:focus {
		.gallery-item-link {
			opacity: 1;
			.link-block {
				transform: translateY(-50%);
			}
		}
	}
}

.gallery-item-caption {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	color: #fff;
	padding: $mgpd;
	width: 100%;
	transition: opacity .3s;
	@include border-radius(inherit);
	&:after {
		content: "";
		left: 0;
		bottom: 0;
		position: absolute;
		width: 100%;
		height: 80px;
		z-index: 0;
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.5) 100%);
		@include border-radius(inherit);
	}
	a {
		color: inherit;
	}
	h3, h4, h5, p {
		font-size: 1.1em;
		font-weight: 400;
		color: inherit;
		margin: 0;
	}
	p {
		font-size: 11px;
		opacity: .8;
		font-weight: 300;
	}
	.item-cat, .item-title {
		position: absolute;
		left: $mgpd;
		z-index: 3;
	}
	.item-cat {
		top: $mgpd;
		letter-spacing: .04em;
		background: rgba(0,0,0,.3);
		padding: 3px 7px;
	}
	.item-title {
		bottom: $mgpd;
	}
	&.dark {
		color: #0D0D0D;
		&:after {
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.7) 100%);
		}
		.item-cat {
			background: rgba(255,255,255,.3);
		}
	}
}
.gallery-item-link {
	opacity: 0;
	transition: .4s;
	.link-block {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		transform: translateY(40%);
		transition: all 0.3s;
		transform-style: preserve-3d;
		z-index: 2;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: #000;
		opacity: 0.7;
		z-index: 1;
		transition: all 0.3s;
		@include border-radius(inherit);
	}
	a.btn {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
		font-size: 11px;
		line-height: 20px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 5px 12px;
		margin: 3px 5px;
		@include border-radius();
		&:hover, &:focus {
			border-color: $primary-color;
			background: $primary-color;
			color: #fff;
			outline: none;
		}
	}
	a:not(.btn) {
		overflow: hidden;
		color: #fff;
		display: inline-block;
		width: 44px;
		height: 44px;
		line-height: 42px;
		font-size: .875em;
		@include border-radius(50%);
		border: 1px solid #fff;
		margin: 0 5px;
		z-index: 8;
		&:hover {
			background-color: #fff;
			color: $primary-color;
		}
	}
}

/* Filter by Category */
div[class*='filtered-menu'] {
	width: 100%;
	margin: ($mgpd * 2) 0;
	text-align: center;
	position: relative;
	z-index: 1;
	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	} 
	li{
		background: #ececec;
		color: $default-color;
		display: inline-block;
		font-size: 12px;
		font-weight: 600;
		line-height: 24px;
		padding: 10px 20px;
		text-transform: uppercase;
		text-align: center;
		cursor: pointer;
		margin-right: -2px;
		position: relative;
		transition: all 0.3s;
		&.active, &:hover{
			background: $primary-color;
			color: #fff;
		}
		&:first-child {
			border-top-left-radius: $radius;
			border-bottom-left-radius: $radius;
		}
		&:last-child {
			border-top-right-radius: $radius;
			border-bottom-right-radius: $radius;
		}
	}
	&.al-left {
		text-align: left;
	}
	&.al-right {
		text-align: right;
	}
	&.style-v2 {
		margin-bottom: $mgpd;
		li {
			padding: 4px 10px;
			font-weight: 400;
			font-size: 1em;
			text-transform: inherit;
			background: none;
			&.active, &:hover {
				color: $primary-color;
			}
			&:not(:last-child) {
				&:after {
					content: "/";
					display: inline-block;
					padding-left: 15px;
					font-weight: 300;
					font-size: .75em;
					color: #dedede;
				}
			}
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
}
.mfp-title {
	font-size: 13px;
	color: #fff;
	small {
		font-size: 11px;
		opacity: 0.9;
	}
}
/* ==========================================================================
    18.0 Responsive Style
   ========================================================================== */ 
@media screen and (min-width: 1800px){
	.banner-static {
		min-height: 420px;
		&.banner-small {
			height: 220px;
			min-height: inherit;
			.is-transparent & {
				height: 310px;
			}
		}
		.is-transparent & {
			min-height: 520px;
		}
	}
}
@media only screen and (min-width : 1200px) {
	.is-transparent .banner-slider {
		height: 625px;
		.banner-content {
			padding-top: 80px;
		}
	}
	.banner-slider.slider-large {
		height: 700px;
	}
	.pad-r {
		padding-right: 60px;
	}
	.pad-l {
		padding-left: 60px;
	}
	.row-vm .col-md-6.pad-r {
		padding-right: 45px;
	}
	.row-vm .col-md-6.pad-l {
		padding-left: 45px;
	}
	.reverses .col-md-6.pad-r{
		padding-right: 15px;
		padding-left: 45px;
	}
	.reverses .col-md-6.pad-l{
		padding-left: 15px;
		padding-right: 45px;
	}
}
@media only screen and (min-width : 992px) and (max-width : 1199px) {
	.pad-r {
		padding-right: 45px;
	}
	.pad-l {
		padding-left: 45px;
	}
	.row-vm .col-md-6.pad-r {
		padding-right: 30px;
	}
	.row-vm .col-md-6.pad-l {
		padding-left: 30px;
	}
	.reverses .col-md-6.pad-r{
		padding-right: 15px;
		padding-left: 30px;
	}
	.reverses .col-md-6.pad-l{
		padding-left: 15px;
		padding-right: 30px;
	}
}

@media only screen and (max-width : 1199px) {
	body{
		font-size: 15px;
	}
	.owl-nav{
		.owl-next{ 
			right: 0;
		}
		.owl-prev{
			left: 0;
		}
	}
	.navbar-nav {
		> li{
			> a{
				font-size: 13px;
				padding-left: 12px;
				padding-right: 12px;
			}
			&.quote-btn .btn{
				font-size: 12px;
			}
		}
		.dropdown-menu {
			font-size: 13px;
		}
	}
	.banner-text {
		font-size: 14px;
		&.wide-md {
			max-width: 460px;
		}
		&.wide-lg {
			max-width: 580px;
		}
	}
	.banner-slider {
		.banner-text {
			max-width: 460px;
		}
	}
	.banner-intro .banner-text {
		padding-top: ($space + 40);
		padding-bottom: ($space + 40);
	}
	.banner-slider .banner-text, .banner-intro .banner-text {
		font-size: ($font-size - 1);
	}
	.img-wrapper{
		max-width: 410px;
	}
	.footer-widget {
		font-size: 12px;
		line-height: 22px;
	}
	.al-center-md, .center-md {
		text-align: center;
	}
	.al-right-md {
		text-align: right;
	}
	.al-left-md {
		text-align: left;
	}
}
@media only screen and (max-width : 1199px) and (min-width: 992px){
	.col-md-3 .feature h3, .col-md-3 .feature h4{
		font-size: .875em;
	}
}
@media only screen and (max-width : 991px) {
	body{
		font-size: 14px;
		-webkit-text-size-adjust:100%;
	}
	.container{
		max-width: 720px;
	}
	.section-pad, .section-pad-lg{
		padding-top: ($space - 30);
		padding-bottom: ($space - 30);
	}
	.res-m-bttm{
		margin-bottom: $mgpd-md;
	}
	.res-m-bttm-sm, .form-m-bttm {
		margin-bottom: $mgpd-sm;
	}
	.res-m-bttm-xs {
		margin-bottom: $mgpd-xs;
	}
	.res-m-bttm-lg {
		margin-bottom: ($mgpd * 2);
	}
	.gaps {
		height: $mgpd;
		&.size-lg {
			height: ($mgpd-md + 15);
		}
		&.size-md {
			height: $mgpd-sm;
		}
		&.size-section {
			height: ($space - 30);
		}
	}
	.npr{
		padding-left: 0; 
	}
	.npl {
		padding-right: 0;
	}
	.image-on-left .imagebg, .image-on-right .imagebg {
	    width: 40%;
	}
	.image-on-right .imagebg {
	    left: 60%;
	}
	.post.post-loop, .feature, .team-profile, .team-member {
		margin-top: ($mgpd-md + 10);
	}
	.feature .fbox-content {
		h3, h4 {
			margin-bottom: $mgpd-xs;
		}
	}
	.feature-row:first-child, .feature-row.mgfix, .blog-posts:first-child, .blog-posts.mgfix, .team-member-row:first-child, .team-member-row.mgfix {
		margin-top: -$mgpd-md;
	}
	.feature-row.row-overup {
		margin-top: -80px;
	}
	.team-member-row.mgtop {
		margin-top: -$mgpd;
	}
	.team-member-row + a.btn-center {
		margin-top: $mgpd-md;
	}
	.team-member .team-info {
		margin-top: 15px;
	}
	.sidebar-right{
		margin-top: $mgpd-lg;
	}
	.first[class*="col-"].odd{
		clear: inherit;
	}
	.site-by, .site-copy{
		width: 100%;
		text-align: left;
		font-size: 12px;
	}
	.site-by {
		margin-top: 10px;
	}
	.call-action.has-parallax.cta-large .cta-block {
	    padding: 80px 0;
	}
	.form-group {
		.form-field + .form-field {
			margin-top: $mgpd-sm;
		}
		.form-field + ul.form-field {
			margin-top: 5px;
		}
	}
	.topbar, .top-nav a {
		font-size: 12px;
	}
	.top-contact span {
		font-size: 1em;
		.topbar & {
			font-size: 1em;
		}
	}
	.header-top {
		margin-top: 15px;
		> * {
			margin-left: 20px;
			&:first-child {
				margin-left: 0;
			}
		}
		.social.social-s2 li a, .top-contact .fa {
			height: 30px;
			width: 30px;
			line-height: 28px;
			font-size: 13px;
		} 
		.top-contact .fa {
			font-size: 14px;
		}
		.quote-btn .btn {
			padding: 7px 16px;
		}
	}
	.site-header .navbar-brand img {
		max-height: 40px;
	}
	.banner-text .page-title{
		font-size: 2em;
		margin-bottom: 6px;
	}
	.banner-static {
		min-height: 260px;
		&.banner-small {
			height: 180px;
		}
		&.banner-intro{
			min-height: 420px;
		}
		.is-transparent & {
			min-height: 320px;
		}
		.banner-cpn {
			padding-top: $mgpd-md;
			padding-bottom: $mgpd-md;
			.banner-text{
				max-width: 450px;
			}
			.page-breadcrumb {
				max-width: 250px;
			}
		}
	}
	.banner-slider{
		height: 360px;
		&.slider-large {
			height: 480px;
		}
		.banner-boxed {
			padding: 25px 25px 30px;
		}
		.banner-content {
			padding-top: 15px;
			padding-bottom: 15px;
		}
		.carousel-control {
			display: none;
			opacity: 0;
		}
	}
	.statistics {
		.stat-block{
			padding: $space 0;
		}
		.stat-wrapper {
			padding: 20px 0 10px;
		}
		.stat-col{
			border-right: 0px;
			padding: 20px 0;
		}
	} 
	.post-single .message{
		margin-top: 0;
		.message-group > .gaps{
			height: 10px;
		}
	}
	.content {
		.list-style li:before {
			top: 1px;
		}
	}
	.footer-widget {
		font-size:14px; 
		line-height:24px;
	}
	.footer-widget{
		.footer-col:nth-child(3){
			clear: both;
		}
		.footer-col {
			.col-md-6{
				&:last-child{
					padding-left: 0;
				}
			}
		}
		.wgs-content .menu + .menu {
			margin-top: 10px;
		}
	}
	.footer-col {
		.col-md-6{
			&:last-child{
				padding-left: 0;
			}
		}
	}
	.quotes {
		margin-top: $mgpd-md;
		&:first-child {
			margin-top: 0;
		}
	}
	.contact-list li span {
		line-height: inherit;
	}
	.content-section {
		margin-top: $mgpd-md;
	}
	hr, .hr {
		margin-top: $mgpd-md;
		margin-bottom: $mgpd-md;
		&:last-child {
			margin-bottom: 0;
		}
		&:first-child {
			margin-top: 0;
		}
	}
	.logos-lists {
		.row {
			margin-left: -10px;
			margin-right: -10px;
		}
		.row + .row {
			margin-top: 20px;
		}
		.col-sm-3, .col-md-3 {
			width: 25%; 
			padding-left:10px;
			padding-right: 10px;
			float: left;
		}
	}
	.faqs-s1 .faqs.accordion {
		margin-top: 10px;
		.panel:last-child {
			margin-bottom: -10px;
			.panel-body{
				padding-bottom: 10px;
			}
		}
	}
	.feature-row, .team-member-row {
	   .even[class*='col-sm-6'] {
	   	clear: left;
	   }
	}
	.content img.alignright, .content img.alignleft { 
		max-width: 350px;
	}
	.al-center-sm, .center-sm {
		text-align: center;
	}
	.al-right-sm {
		text-align: right;
	}
	.al-left-sm {
		text-align: left;
	}
}
@media only screen and (min-width : 768px) and (max-width : 991px) {
	.feature-intro .res-m-bttm.col-sm-6 {
		margin-bottom: 0;
	}
	.feature-row .col-sm-6 .feature {
		h3:not(.lead), h4:not(.lead) {
			font-size: 1.125em;
		}
	}
	.navbar-nav {
		> li{
			> a{
				font-size: 11px;
				padding: 15px 10px;
			}
			&.quote-btn{
				margin-left: 5px;
				.btn{
					padding: 5px 15px;
					font-size: 11px;
				}
			}
		}
	}
	.header-s1 .navbar-nav{
		> li > a {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		> li.quote-btn {
			margin-top: 10px;
		}
	}
	.call-action {
		.cta-sameline {
			padding-right: 180px;
		}
		.cta-num {
			font-size: 1em;
			strong {
				font-size: 1.375em;
			}
		}
	}
	.site-header .navbar-brand img {
		max-height: 28px;
	}
	.row-vm{
		align-items: baseline
	}
}
@media only screen and (max-width : 767px) {
	.container{
		padding-left: 30px;
		padding-right: 30px;
		max-width: 100%;
	}
	.row-vm{
		display: block;
	}
	.boxed-w{
	    .heading-box{
	        padding: 40px 40px 0 20px;
	            span{
	            margin-top: $mgpd-md;
	            padding-left: 0;
	            &:before{
	                display: none;

	            }
	        } 
	    }
	}
	.image-on-left .imagebg, .image-on-right .imagebg {
	    width: 0%;
	}
	.owl-nav{
		.owl-prev, .owl-next{
			right: 0;
		}
		.owl-prev{
			left:0;
		}
	}
	.top-nav {
		li:first-child {
			margin-left: 0;
		}
		li a {
			padding:6px 10px;
			.topbar & {
				line-height: 24px;
				padding: 6px 5px;
			}
		}

	}
	.top-contact {
		line-height: 24px;
		li {
			margin-left: 12px;
			display: inline-block;
			float: none;
			&:first-child {
				margin-left: 0;
			}
		}
		.topbar & {
			span {
				line-height: 24px;
			}
			.fa {
				line-height: 22px;
			}
		}
	}
	.topbar {
		.top-aside {
			max-width: 100%;
			margin: 0;
			width: 100%;
			float: left;
			text-align: center;
			> * {
				width: 100%;
			}
			&:not(.hidden-xs) + .top-aside {margin-top: 5px;}
		}
	}
	.header-top .top-contact .fa {
		font-size: 16px;
	}
	.navbar-toggle {
		float: left;
		margin-top: 6px;
		margin-bottom: 6px;
		padding:10px 12px;
		background-color: $primary-color;
		.icon-bar {
			background-color: #fff;
		}
	}
	.navbar-header{
		background: $primary-color;
		clear: both;
		width: 100%;
		padding:0 15px;
	}
	.navbar-nav {
		margin: 0;
		float: none;
		padding-bottom: 20px;
		text-align: center;
		li {
			border-bottom: 1px solid rgba(0,0,0,0.1);
			a {
				padding:12px 10px;
				font-size: 14px;
				font-weight: 400;
				color: #fff;
				&.btn {
				    width: 150px;
				    margin: 6px auto 0;
				}
				.header-s1 & {
					padding-top: 12px;
					padding-bottom: 12px;
				}
			}
			&.quote-btn {
				float: none;
				border-bottom: 0;
				.btn:hover {
					background: $alternet-color;
				}
			}
			.dropdown-menu{
				position: static;
				background: none;
				width: 100%;
				display: block;
				opacity: 1 !important;
				visibility: visible !important;
				box-shadow: none;
				text-align: center;
				float: none;
				li {
					border-top: 1px solid rgba(0,0,0,0.1);
					border-bottom: 0 none;
					a {
						font-size: 13px;
						color: #fff;
						font-weight: 400;
						&:hover, &:focus {
							background: #fff;
							color: $primary-color;
							opacity: 1;
						}
					}
				}
			}
		}
		> li.active a, > li > a:hover, > li > a:focus {
			color: #fff;
		}
		.dropdown-menu li.dropdown > a::after{
			display: none;
		}
	}
	.site-header  {
		.navbar-header {
			float: left;
			margin: 10px 0 0;
			.quote-btn {
				display: block;
				float: right;
				margin: 6px 0;
			}
		}
		.navbar-collapse{
			float: none;
			text-align: center
		}
	}
	.header-s1 {
		.navbar .container {
			padding-left: 0;
			padding-right: 0;
		}
		.navbar-brand {
			position: absolute;
			left: 15px;
			width: 200px;
		}
		.navbar-toggle {
			float: right;
			margin:12px 15px 12px 0;
		}
		.navbar-header {
			background: none;
			padding:0;
			margin-top: 0;
			.quote-btn {
				display: none;
			}	
		}
		.navbar-collapse {
			margin: 0;
			background-color: $primary-color;
			color: #fff;
			width: 100%;
		}
		&.is-transparent {
			.topbar, .navbar {
				position: static;
			}
			.navbar-brand {
				.logo-light {
					display: none;
				}
				.logo-dark {
					display: block;
				}
			}
			.topbar {
				color: $topbar-color;
				background: $topbar-bgcolor;
				border-color: rgba(0, 0, 0, 0.0667);
				a:hover:not(.btn) {
					color: $topbar-color;
				}
			}
			.topbar + .navbar {
				margin-top: 0;
			}
			&:after {
				display: none;
			}
			.navbar-collapse, .top-contact .fa {
				color: $topbar-color;
			}
		} 
	}
	.section-pad, .section-pad-lg{
		padding-top:  ($space - 30);
		padding-bottom: ($space - 30)
	}
	.res-s-bttm{
		margin-bottom: $mgpd-md;
	}
	.res-s-bttm-lg{
		margin-bottom: ($mgpd-md + 10);
	}
	.res-s-bttm-sm{
		margin-bottom: $mgpd-sm;
	}
	.res-s-bttm-xs{
		margin-bottom: $mgpd-xs;
	}
	.gaps {
		&.size-lg {
			height: $mgpd-md;
		}
		&.size-section {
			height: ($space - 30);
		}
	}
	.feature-intro .row + .row {
		margin-top: $mgpd-md;
	}
	.space-top, .space-bottom, .space-top-sm, .space-bottom-sm, .space-top-md, .space-bottom-md {
		margin-top: $mgpd-md;
	}
	.tab-custom {
		.tab-content {
			padding:25px 15px 30px;
		} 
		.nav-tabs li > a{
			font-size: 13px;
			line-height: 18px;
		}
		&.tab-simple  .nav-tabs li {
			margin-right: -2px;
			> a {
				padding: 12px 10px;
				font-size: 12px;
			}
		}
	}
	.accordion {
		.panel-title {
			font-size: 1.125em;
			font-weight: 400;
		}
	}
	.content {
		.npl+.pad-0 {
			margin-top: 25px;
		}
	}
	.pad-xs-0{
		padding: 0px;
	}
	.banner-text {
		font-size: 12px;
		&.wide-md {
			max-width: 320px;
		}
		&.wide-lg {
			max-width: 420px;
		}
		.btn {
			padding: 10px 16px;
			font-size: 12px;
			letter-spacing: 0;
			& +.btn {
				margin-left: 10px;
			}
		}
	}
	.banner-static {
		min-height: 200px;
		&.banner-small {
			height: 150px;
			.banner-text, .breadcrumb {
				font-size: 10px;
			}
		}
		&.banner-intro{
			min-height: 320px;
		}
		.is-transparent & {
			min-height: 200px;
		}
		.banner-cpn .page-breadcrumb {
			max-width: 100%;
			transform: translateY(0);
			position: static;
			margin-top: $mgpd-sm;
			float: left;
			text-align: left;
		}
		.banner-cpn {
			padding-top: $mgpd-md;
			padding-bottom: $mgpd-md;
		}
	}
	.banner-slider{
		height: 280px;
		&.slider-large {
			height: 360px;
		}
		.banner-boxed {
			padding: 20px 20px 25px;
		}
	}
	.banner-intro {
		.container > .content {
			padding-top: 0;
			padding-bottom: 0;
		}
		.banner-text {
			padding-top: ($mgpd * 2);
			padding-bottom: ($mgpd * 2);
			.is-transparent & {
				margin-top: 0;
			}
		}
	}
	.is-transparent .banner-static .banner-cpn {
		margin-top: 0;
	}
	.banner-slider .banner-text, .banner-intro .banner-text {
		font-size: 12px;
		h1, h2 {
			font-size: 1.5em;
		}
	}
	.call-action {
		.cta-sameline {
			padding-right: 0;
			text-align: center;
			h2:first-child, h3:first-child {
				margin-top: -10px;
			}
			.btn {
				transition: none;
				position: static;
				margin-top: 20px;
				transform: translate(0, 0);
			}
		}
		&.cta-mini .cta-sameline h2:first-child, &.cta-mini .cta-sameline h3:first-child {
			margin-top: 0;
		}
		&.call-action .cta-num strong {
			font-size: 1.3em;
		}

		&.call-small .cta-block {
			padding-top: $space-sm;
			padding-bottom: $space-sm;
		}
		.cta-sameline .btn, .cta-sameline .cta-num{
			position: static;
			margin-top: 20px;
		}
	}
	.statistics .stat-block {
		padding-top: $space-sm;
		padding-bottom: $space-sm;
	}
	.feature-imagebox + .feature-imagebox {
		margin-top: 40px;
	}
	.team-profile {
		text-align: center;
		.team-photo img {
			max-width: 300px;
		}
		.team-info {
			margin-top: $mgpd-sm;
		}
	}
	.team-member .team-info .sub-title {
		font-size: 1em;
	}
	.icon-box + h4, .icon-box + h3{
		margin-top: $mgpd-sm;
		margin-bottom:  $mgpd-sm;
		&:last-child {
			margin-bottom: 0
		}
	}
	.section-logos.section-pad-sm {
		padding-top: ($space-sm - 25);
		padding-bottom: ($space-sm - 25);
	}
	.copyright{
		text-align: center;
		.social{
			float: none;
			margin-top: 15px;
		}
	}
	.al-center-xs, .center-xs {
		text-align: center;
	}
	.al-right-xs {
		text-align: right;
	}
	.al-left-xs {
		text-align: left;
	}
	/* Gallery @v110 */
	.gallery-list {
		margin-left: -6px;
		margin-right: -6px;
		li {
			padding: 6px;
		}
	}
}
@media only screen and (max-width : 599px) {
	.feature {
		&-s1 .feature, &-s2 .feature {
			padding: $mgpd;
			min-height: 330px;
		}
		&-row .feature {
			h3:not(.lead), h4:not(.lead) {
				font-size: 1.125em;
			}
		}
	}
	.content img.alignright, .content img.alignleft { 
		float: none;
		display: block;
		max-width: 100%;
		margin: 0 0 15px;
	}
	.topbar {
		display: none
	}
	.content-cards {
		margin-top: -25px;
	}
	.cards {
		height: 240px;
	}
	/* Gallery @v110 */
	.gallery {
		&-col5, &-col2, &-col4, &:not([class*="gallery-col"]) {
			.gallery-list > li {
				width: 50%;
			}
		}
		&.gallery-with-caption {
			&[class*="gallery-col"] {
				.gallery-list > li {
					width: 100%;
				}
			}
		}
	}
	div[class*='filtered-menu'] li {
		padding: 6px 10px;
		font-size: 11px;
	}
	div[class*='filtered-menu'].style-v2 li {
		padding: 5px 4px;
		&:not(:last-child):after {
			padding-right: 9px;
		}
	}
}
@media only screen and (max-width : 480px) {
	body {
		min-width: 320px;
	}
	.heading-section{
		font-size: 1.5em;
		&.heading-sm {
			font-size: 1.125em;
		}
	}
	.heading-lead {
		font-size: 1.875em;
		&.heading-sm {
			font-size: 1.5em;
		}
	}
	.banner-text{
		font-size: 12px;
		h1, h2 {
			font-size: 1.375em;
		}
		.btn {
			padding: 8px 12px;
			font-size: 11px;
		}
		.banner-cta:not(:first-child) {
			margin-top: 18px;
		}
		&.wide-sm, &.wide-md, &.wide-lg {
			max-width: 100%;
			width: 90%;
		}
	}
	.banner-slider{
		height: 180px;
		.banner-text:not(.banner-boxed) {
			max-width: 230px;
		}
		&.slider-large {
			height: 280px;
		}
		.banner-boxed {
			padding: 10px 12px 15px;
			font-size: 9px;
			max-width: 70%;
		}
	}
	.banner-static.banner-intro{
		min-height: 240px;
	}
	.banner-static .banner-cpn {
		padding-top: $mgpd-sm;
		padding-bottom: $mgpd-sm;
	}
	.banner-intro {
		.banner-text {
			padding-top: $mgpd-sm;
			padding-bottom: $mgpd-sm;
		}
		.banner-cpn {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.banner-slider .banner-text, .banner-intro .banner-text {
		h1, h2 {
			font-size: 1.5em;
		}
	}
	.carousel-control .glyphicon-chevron-left, .carousel-control .glyphicon-chevron-right{
		height: 45px;
		width: 45px;
		font-size: 24px;
	}
	.call-action {
		.cta-block {
			padding: $space-md 0;
		}
	}
	.contact-group{
		margin-bottom:40px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.team-member-row [class*="col-"], .feature-row [class*="col-"] {
		width: 100%;
	}
	.feature-row.row-overup {
		margin-top: 5px;
	}
	.feature-s1 .feature, .feature-s2 .feature {
		min-height: auto;
	}
	.team-member {
		text-align: center;
		.team-photo {
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;	
		}
	}
	.team-profile .team-photo {
		max-width: inherit
	}
	.logos-lists {
		.row:first-child {
			margin-top: -10px;
		}
		.row + .row {
			margin-top: 0;
		}
		.col-sm-3, .col-md-3 {
			width: 50%;
			margin: 10px 0;
		}
		&.logos-lists-flat {
			.row:last-child {
				.col-sm-3, .col-md-3 {
					&:nth-child(1) .logo-item, &:nth-child(2) .logo-item {
						border-bottom-color: rgba(0,0,0,0.08);
					}
				}
			}
			.col-sm-3, .col-md-3 {
				margin: 0;
				&:nth-child(2n+2) .logo-item {
					border-right-color: transparent;
				}
			}
		}
	}
	img.alignright, img.alignleft, img.aligncenter{
		width: 100%;
	}
	img.no-resize, .no-resize {
		width: auto;
		max-width: 100%;
	}
}

@media only screen and (max-width : 375px) {
	.btn + .btn{
		margin-top: 5px;
	}
	h1, .h1, .heading-lg, .heading-lg-lead{
		font-size: 1.875em;
	}
	h2, .h2{
		font-size: 1.5em;
	}
	.banner-slider .banner-text, .banner-intro .banner-text {
		font-size: 10px;
	}
	.banner-boxed > p {
		display: none;
	}
}    
@media only screen and (max-width : 1280px) {
	/* parallax disable on ipad/iphone */
	.has-parallax {
		background-position: 50% 50% !important;
		background-attachment: scroll !important;
	}
	/* @v110*/
	.has-carousel.row-feature, .has-carousel.gallery-list {
		.owl-nav .owl-next {
			right: 0;
			background-image: url(../image/arrow-next-w.png);
		}
		.owl-nav .owl-prev {
			left: 0;
			background-image: url(../image/arrow-prev-w.png);
		}
	}
}
/* ==========================================================================
    19.0 - Version Wise Css 
   ========================================================================== */
.has-carousel {
	display: none;
	&.owl-loaded {
		display: block;
	}
	[class*="col-"] {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
	&.feature-row {
		.owl-nav .owl-prev, .owl-nav .owl-next {
			margin-top: ($mgpd-lg / 2);
		}
	}
}
.map-contact {
	position: relative;
	&:not(.map-holder) {
		height: 380px;
	}
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
/* ==========================================================================
    20.0 - Extra / Additional / Custom CSS / Your Additional
   ========================================================================== */
.demo-sample-icon .icon-box {
	margin-right: 10px;
}
.demo-sample-icon hr {
	margin-top: 25px;
	margin-bottom: 25px;
}
@media only screen and (max-width : 479px) {
	.page-typo .col-md-3.col-sm-6.col-xs-6 {
		width: 100%;
	}
}

/* ===================== OWL CSS ===================*/
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
	display: none;
}
.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel .owl-item img {
	display: block;
	width: 100%;
	-webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
	-webkit-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: -o-grab;
	cursor: -ms-grab;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
	transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	/*background: url("owl.video.play.png") no-repeat;*/
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transition: scale 100ms ease;
	-moz-transition: scale 100ms ease;
	-ms-transition: scale 100ms ease;
	-o-transition: scale 100ms ease;
	transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
	-webkit-transition: scale(1.3, 1.3);
	-moz-transition: scale(1.3, 1.3);
	-ms-transition: scale(1.3, 1.3);
	-o-transition: scale(1.3, 1.3);
	transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	-moz-background-size: contain;
	-o-background-size: contain;
	background-size: contain;
	-webkit-transition: opacity 400ms ease;
	-moz-transition: opacity 400ms ease;
	-ms-transition: opacity 400ms ease;
	-o-transition: opacity 400ms ease;
	transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

.cookieConsentContainer {
	z-index: 999;
	width: 350px;
	min-height: 20px;
	box-sizing: border-box;
	padding: 30px 30px 30px 30px;
	background: #232323;
	overflow: hidden;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: none;
}
.cookieConsentContainer .cookieTitle a {
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 22px;
	line-height: 20px;
	display: block;
}
.cookieConsentContainer .cookieDesc p {
	margin: 0;
	padding: 0;
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 13px;
	line-height: 20px;
	display: block;
	margin-top: 10px;
} .cookieConsentContainer .cookieDesc a {
	  font-family: OpenSans, arial, "sans-serif";
	  color: #FFFFFF;
	  text-decoration: underline;
  }
.cookieConsentContainer .cookieButton a {
	display: inline-block;
	font-family: OpenSans, arial, "sans-serif";
	color: #FFFFFF;
	font-size: 14px;
	font-weight: bold;
	margin-top: 14px;
	background: #000000;
	box-sizing: border-box;
	padding: 15px 24px;
	text-align: center;
	transition: background 0.3s;
}
.cookieConsentContainer .cookieButton a:hover {
	cursor: pointer;
	background: #3E9B67;
}

@media (max-width: 980px) {
	.cookieConsentContainer {
		bottom: 0px !important;
		left: 0px !important;
		width: 100%  !important;
	}
}